<template>
  <section-container id="start-section" additional-class="p-0 pb-5" v-if="!isShowGlobalError">
    <div class="header-img">
      <img src="@/assets/img/smv-burgdorf-2018-foto-stephan-boegli-dsc-3592.jpg" :alt="$t('section.start.headerImage')">
    </div>

    <div class="section-content">
      <h1>{{ $t('section.start.greeting', {user: memberData.firstName}) }}</h1>
      <p :class="{'mb-5': !isEmulatingMember}">{{ $t('section.start.greetingMessage') }}</p>

      <p class="text-muted" v-if="isEmulatingMember">
        {{ $t('section.start.emulatingMember', {releaseMember: releaseMemberData.firstName + ' ' + releaseMemberData.surname}) }}
      </p>
      <p :class="{'mb-0': (isEmulatingMember && releaseMemberData), 'mb-5': (!isEmulatingMember)}">
        <router-link to="membercard" class="btn btn-outline-dark d-inline-block">
          <i class="bi d-inline-block mb-2" :class="{'bi-person-badge': isEmulatingMember, 'bi-file-person': !isEmulatingMember}" style="font-size: 1.5rem"></i><br>
          {{ (isEmulatingMember && releaseMemberData) ? $t('section.start.showReleaseMemberCard', {releaseMember: releaseMemberData.firstName}) : $t('section.start.showMemberCard') }}
        </router-link>
      </p>
      <p class="mb-5" v-if="isEmulatingMember">
        <router-link :to="{name: 'start'}" custom v-slot="{href, navigate}">
          <a :href="href" class="btn btn-outline-dark d-inline-block mt-3" @click="resetReleaseMemberData(); wrapNavigate(navigate, $event)">
            <i class="bi bi-box-arrow-in-left"></i> {{ $t('menu.releasedMembers.back') }}
          </a>
        </router-link>
      </p>

      <div v-if="isClubAdmin && !isEmulatingMember && clubFunctions.length > 0">
        <p class="mb-2">
          {{ $t('section.start.adminMessage', clubFunctions.length) }}<br>
        </p>
        <div class="overflow-auto">
          <div class="col">
            <ul class="member-list">
              <li v-for="(club, index) in clubFunctions" :key="index">
                <router-link :to="{name: 'club-profile', query: {clubId: club.organId}}"
                             class="btn btn-outline-dark d-inline-block">
                  {{ club.organ || club.organId }}<br>
                  <small class="font-weight-normal">{{ $t('section.start.editClubMembers') }}</small>
                </router-link>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="mt-4" v-if="isClubAdminLight && !isEmulatingMember && clubFunctionsLight.length > 0">
        <p class="mb-2">
          {{ $t('section.start.adminLightMessage', clubFunctionsLight.length) }}<br>
        </p>
        <div class="overflow-auto">
          <div class="col">
            <ul class="member-list">
              <li v-for="(club, index) in clubFunctionsLight" :key="index">
                <router-link :to="{name: 'clubMembers', query: {clubId: club.organId}}"
                             class="btn btn-outline-dark d-inline-block">
                  {{ club.organ || club.organId }}<br>
                  <small class="font-weight-normal">{{ $t('section.start.showClubMembers') }}</small>
                </router-link>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="mt-4" v-if="isClubAdminLSPA && !isEmulatingMember && clubFunctionsLSPA.length > 0">
        <p class="mb-2">
          {{ $t('lspa.startPage.title', clubFunctionsLSPA.length) }}<br>
        </p>
        <div class="overflow-auto">
          <div class="col">
            <ul class="member-list">
              <li v-for="(club, index) in clubFunctionsLSPA" :key="index">
                <router-link :to="{name: 'club-lspa', query: {clubId: club.organId}}"
                             class="btn btn-outline-dark d-inline-block">
                  {{ club.organ || club.organId }}<br>
                  <small class="font-weight-normal">{{ $t('lspa.startPage.smallTitle') }}</small>
                </router-link>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="mt-4" v-if="memberData.releasedMembers && memberData.releasedMembers.length > 0 && !isEmulatingMember">
        <p class="mb-2">
          {{ $t('section.start.familyMembers') }}
        </p>
        <div class="overflow-auto">
          <div class="col mb-3">
            <div class="member-list">
              <li v-for="member in memberData.releasedMembers" :key="member.id">
                <router-link :to="{name: 'start', query: {releaseMemberId: member.memberId}}" class="btn btn-outline-dark h-100">
                  <strong>{{ member.firstName }} {{ member.surname }}</strong><br>
                  <small class="font-weight-normal">{{ member.memberId }}</small>
                </router-link>
              </li>
            </div>
          </div>
        </div>
      </div>

      <p class="mt-5">
        <router-link :to="{name: 'member-offers'}" custom v-slot="{href, navigate}">
          <a :href="href" class="btn btn-outline-dark d-inline-block" @click="wrapNavigate(navigate, $event)">
            <i class="bi bi-bag-heart d-inline-block mb-2" style="font-size: 1.5rem;"></i><br>
            {{ $t('offers.title') }}
          </a>
        </router-link>
      </p>

      <MembercardAds keywords="start" class="mb-2"/>
    </div>

  </section-container>
  <LoaderOverlay v-if="isLoading"/>

</template>

<script>
import {mapActions, mapState} from "pinia"
import {useMemberStore} from "@/store/modules/member/member.js";
import {useLoginStore} from "@/store/modules/login/login.js";
import {useMessagesStore} from "@/store/modules/messages/messages.js";

import SectionContainer from "@/components/ui/SectionContainer";
import LoaderOverlay from "@/components/ui/LoaderOverlay.vue";
import MembercardAds from "@/components/adbutler/MembercardAds.vue";

export default {
  data() {
    return {
      isLoading: true,
    }
  },
  components: {
    LoaderOverlay,
    SectionContainer,
    MembercardAds,
  },
  methods: {
    wrapNavigate(navigate, event) {
      if (this.isMenuOpen) {
        this.toggleMenu();
      }
      navigate(event);
    },
    async resetReleaseMemberData() {
      await this.clearReleaseMemberData();
    },
    ...mapActions(useMemberStore, [
      "clearReleaseMemberData",
      "loadReleaseMemberData",
    ])
  },
  watch: {
    '$route.query': {
      async handler() {
        this.isLoading = true;
        if (this.$route.query.releaseMemberId) {
          await this.loadReleaseMemberData({releaseMemberId: this.$route.query.releaseMemberId, useCache: true, overwrite: true});
        }
        this.isLoading = false;
      },
      immediate: true,
      deep: true,
    },
  },
  computed: {
    ...mapState(
      useMemberStore, {
        memberData: 'getMemberData', 
        releaseMemberData: "getReleaseMemberData", 
        isEmulatingMember: "isEmulatingMember",
        clubFunctions: "getClubFunctions",
        clubFunctionsLight: "getClubFunctionsLight",
        clubFunctionsLSPA: "getClubFunctionsLSPA"
      },
    ),
    ...mapState(
      useMessagesStore, {isShowGlobalError: 'isShowGlobalError'}
    ),
    ...mapState(
      useLoginStore, {
        isAnyKindOfClubAdmin: "isAnyKindOfClubAdmin", 
        isClubAdmin : "isClubAdmin", 
        isClubAdminLight: "isClubAdminLight", 
        isClubAdminLSPA: "isClubAdminLSPA", 
        isClientInitialized: "isClientInitialized"}
    ),
  },
};
</script>

<style scoped lang="scss">
.section-content {
  padding: 0 1.5rem;
}

.member-list {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: center;
  list-style: none;
  width: 100%;
  gap: 1rem;
  margin: 0;
  padding: 0;

  > li {
    flex: 1 0 calc(50% - 1rem);

    @include sm {
      flex: 0 0 auto;
      max-width: 15rem;
    }

    > a {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
