
<template>
  <div class="modal modal-lg fade" tabindex="-1">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          {{ $t('squad.createNewSquad') }}
        </div>
        <div class="modal-body">
          <form>
            <div class="mb-3">
              <label class="col-form-label" for="quad-no">Name Deutsch:</label>
              <input id="quad-name" class="form-control" type="text" v-model="squad.description">
            </div>
            <div class="mb-3">
              <label class="col-form-label" for="quad-name-fr">Name Französisch:</label>
              <input id="quad-name-fr" class="form-control" type="text" v-model="squad.description_fr">
            </div>
            <div class="mb-3">
              <label class="col-form-label" for="quad-name-it">Name Italienisch:</label>
              <input id="quad-name-it" class="form-control" type="text" v-model="squad.description_it">
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <Button class="btn btn-secondary" data-bs-dismiss="modal" type="button">Cancel</Button>
          <Button class="btn btn-primary" data-bs-dismiss="modal" type="button" :disabled="!allValuesNotEmpty"
                  @click="saveNewSquad(clubData.data.No)">Speichern
          </Button>
        </div>

      </div>
    </div>
  </div>
</template>

<script setup>


import {computed, ref} from "vue";
import {useClubStore} from "@/store/modules/club/club";
import {useSquadStore} from "@/store/modules/squad/squad";
const clubStore = useClubStore();
const quadStore = useSquadStore();
const clubData = computed(() => clubStore.getClubData);

const squad = ref({
  description: "",
  description_fr: "",
  description_it: ""
})

const saveNewSquad = (clubId) => {
  const squadDto = {};
  squadDto.Description = squad.value.description;
  squadDto.DescriptionFR = squad.value.description_fr;
  squadDto.DescriptionIT = squad.value.description_it;
  squadDto.Club_No=clubId;
  quadStore.newSquadByClub(squadDto);
}

const allValuesNotEmpty = computed(()=> {
  if (squad.value.description.length < 1) {
    return false;
  }
  if (squad.value.description_fr.length < 1) {
    return false;
  }
  if (squad.value.description_it.length < 1) {
    return false;
  }
  return true;
})
</script>

