import ApiClient from "@/api/ApiClient";

import {defineStore} from "pinia";

const SQUAD_BASE_PATH = "/api/squad";
export const useSquadStore = defineStore("squad", {
  state: () => {
    return {
      squadData: {},
    };

  },
  actions: {
    async loadSquadsByClubId(clubId) {
      const squadData = await ApiClient.getRequest(SQUAD_BASE_PATH + '/club?clubId=' + clubId);
      if (squadData?.data) {
        this.squadData.squads = squadData.data;
        this.squadData.clubId = clubId;
      }
    },
    async saveSquadByNo(squad) {
      const squadData = await ApiClient.putRequest(SQUAD_BASE_PATH + '/club', squad);
      if (squadData?.data) {
        const clubNr = squadData.data.Club_No;
        await this.loadSquadsByClubId(clubNr);
      }
    },
    async newSquadByClub(squad) {
      const squadData = await ApiClient.postRequestWithAuthorization(SQUAD_BASE_PATH + '/club', squad);
      if (squadData?.data) {
        const clubNr = squadData.data.Club_No;
        await this.loadSquadsByClubId(clubNr);
      }
    }


  },
  getters: {
    getSquadData: (state) => {
      return state.squadData;
    }
  },
  persist: {
    storage: sessionStorage,
  }

});
