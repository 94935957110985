<template>
  <club-layout :title="clubData.status === 200 ? $t('contact.bankAccount.title', {name: clubData.data.Name}) : $t('club.notFound.title')" :is-loading="isSubmitted">
    <template #content v-if="clubData.status === 200">
      <div class="row border-bottom pb-4 mb-4">
        <div class="col">
          <div class="row mb-3">
            <div class="col-12">
              <p class="mb-2"/>
              <p>
                {{ $t('contact.bankAccount.infotext') }}<br>
              </p>
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-12">
              <p class="mb-2"><strong>{{ $t('contact.bankAccount.methods.title') }} *</strong></p>
              <div class="form-floating has-validation">
                <select id="payment-method" class="form-select"
                        :class="{'is-invalid': !dataValid.Payment_Form, 'is-valid' : isSelected(paymentBaseData.Payment_Form)}"
                        :placeholder="$t('contact.bankAccount.methods.title')" v-model="paymentBaseData.Payment_Form"
                        @keyup="analyzeBaseData" @keyup.enter="submitBaseData" required="true">
                  <option value="Bankzahlung Inland">{{ $t('contact.bankAccount.methods.bankPaymentDomestic') }}</option>
                  <option value="Postzahlung Inland">{{ $t('contact.bankAccount.methods.postPaymentDomestic') }}</option>
                  <option value="Bankzahlung Ausland">{{ $t('contact.bankAccount.methods.bankPaymentForeign') }}</option>
                  <option value="Postzahlung Ausland">{{ $t('contact.bankAccount.methods.postPaymentForeign') }}</option>
                  <option value="SWIFT-Zahlung Ausland">{{ $t('contact.bankAccount.methods.swiftForeign') }}</option>
                </select>
                <label for="display-name" class="form-label">{{ $t('contact.bankAccount.methods.title') }}</label>
                <div class="invalid-feedback" v-show="!dataValid.Payment_Form">{{ $t('contact.bankAccount.validation.invalidPaymentSelection') }}</div>
              </div>
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-12">
              <p class="mb-2"><strong>{{ $t('contact.bankAccount.labels.nameOfInsitute') }} *</strong></p>
              <div class="form-floating has-validation">
                <input type="text" id="institute-name" class="form-control"
                       :class="{'is-invalid': !dataValid.Name, 'is-valid': isRequired(paymentBaseData.Name) && isNotEmptyWithinMaxLength(paymentBaseData.Name, 50)}"
                       :placeholder="$t('contact.bankAccount.labels.nameOfInsitute')" required="true"
                       v-model="paymentBaseData.Name" @keyup="analyzeBaseData" @keyup.enter="submitBaseData">
                <label for="institute-name" class="form-label">{{ $t('contact.bankAccount.labels.nameOfInsitute') }}</label>
                <div class="invalid-feedback" v-show="!dataValid.Name">{{ $t('contact.bankAccount.validation.nameEmptyOrExeeded', {maxLength: 50}) }}</div>
              </div>
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-12">
              <p class="mb-2"><strong>{{ $t('contact.bankAccount.labels.zipCode') }}</strong></p>
              <div class="form-floating has-validation">
                <input type="text" id="zip-code" class="form-control"
                       :class="{'is-invalid': !dataValid.Post_Code, 'is-valid': isEmptyOrWithinMaxLength(paymentBaseData.Post_Code, 20)}"
                       :placeholder="$t('contact.bankAccount.labels.zipCode')"
                       v-model="paymentBaseData.Post_Code" @keyup="analyzeBaseData" @keyup.enter="submitBaseData"
                       maxlength="20">
                <label for="zip-code" class="form-label">{{ $t('contact.bankAccount.labels.zipCode') }}</label>
                <div class="invalid-feedback" v-show="!dataValid.Post_Code">{{ $t('contact.bankAccount.validation.postCodeExceeded', {maxLength: 20}) }}</div>
              </div>
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-12">
              <p class="mb-2"><strong>{{ $t('contact.bankAccount.labels.city') }}</strong></p>
              <div class="form-floating has-validation">
                <input type="text" id="city" class="form-control"
                       :class="{'is-invalid': !dataValid.City, 'is-valid' : isEmptyOrWithinMaxLength(paymentBaseData.City, 30)}"
                       :placeholder="$t('contact.bankAccount.labels.city')" v-model="paymentBaseData.City"
                       @keyup="analyzeBaseData" @keyup.enter="submitBaseData">
                <label for="city" class="form-label">{{ $t('contact.bankAccount.labels.city') }}</label>
                <div class="invalid-feedback" v-show="!dataValid.City">{{ $t('contact.bankAccount.validation.cityExceeded', {maxLength: 30}) }}</div>
              </div>
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-12">
              <p class="mb-2"><strong>{{ $t('contact.bankAccount.labels.country') }}</strong></p>
              <div class="form-floating has-validation">
                <select id="country-code" class="form-select"
                        :class="{'is-invalid': !dataValid.Country_Region_Code, 'is-valid' : isSelected(paymentBaseData.Country_Region_Code) || isEmpty(paymentBaseData.Country_Region_Code)}"
                        :placeholder="$t('contact.bankAccount.labels.country')" v-model="paymentBaseData.Country_Region_Code"
                        @keyup="analyzeBaseData" @keyup.enter="submitBaseData">
                  <option value="CH">{{ $t('contact.bankAccount.countries.ch') }}</option>
                  <option value="FL">{{ $t('contact.bankAccount.countries.fl') }}</option>
                </select>
                <label for="country-code" class="form-label">{{ $t('contact.bankAccount.labels.country') }}</label>
                <div class="invalid-feedback" v-show="!dataValid.Country_Region_Code">{{ $t('contact.bankAccount.validation.invalidCountry', {maxLength: 10}) }}</div>
              </div>
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-12">
              <p class="mb-2"><strong>{{ $t('contact.bankAccount.labels.iban') }} *</strong></p>
              <div class="form-floating has-validation">
                <input type="text" id="iban" class="form-control"
                       :class="{'is-invalid': !dataValid.IBAN, 'is-valid' : validateIBAN(paymentBaseData.IBAN)}"
                       :placeholder="$t('contact.bankAccount.labels.iban')" v-model="paymentBaseData.IBAN"
                       @keyup="analyzeBaseData" @keyup.enter="submitBaseData" required="true">
                <label for="iban" class="form-label">{{ $t('contact.bankAccount.labels.iban') }}</label>
                <div class="invalid-feedback" v-show="!dataValid.IBAN">{{ $t('contact.bankAccount.validation.invalidIBAN', {maxLength: 50}) }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="d-flex gap-2">
          <DefaultButton
            class="btn-outline-dark"
            role="button"
            :label="$t('contact.bankAccount.labels.save')"
            @click="submitBaseData"
            @keyup.enter="submitBaseData"
            onkeypress="submitBaseData"
            :disabled="(!allDataValid || isSubmitted) || !dataChanged"
          />
        </div>
      </div>
      <BootstrapToast/>
    </template>
    <template #content v-else>
      <NoClubFoundAlert/>
    </template>
  </club-layout>

</template>

<script setup>
import {computed, onBeforeMount, onMounted, ref} from 'vue'
import {useClubStore} from "@/store/modules/club/club.js";
import {useContactStore} from "@/store/modules/contact/contact.js";
import {useMessagesStore} from "@/store/modules/messages/messages.js";
import {useRouter} from 'vue-router';
import {useI18n} from 'vue-i18n'
import ClubLayout from "@/components/club/ClubLayout";
import Message from "@/helpers/message.js";
import BootstrapToast from '@/components/ui/BootstrapToast.vue';
import NoClubFoundAlert from "@/components/ui/alert/NoClubFoundAlert.vue";

const router = useRouter();
const clubStore = useClubStore();
const contactStore = useContactStore();
const messageStore = useMessagesStore();
const currentPaymentData = computed(() => contactStore.getPaymentData);
const clubData = computed(() => clubStore.getClubData);
const { t } = useI18n()


let showFailure = ref(false);
let showSuccess = ref(false);
let isSubmitted = ref(false);

let paymentBaseData = ref({
  Contact_No: currentPaymentData.value.Contact_No,
  Payment_Form: currentPaymentData.value.Payment_Form,
  Name: currentPaymentData.value.Name,
  Post_Code: currentPaymentData.value.Post_Code,
  City: currentPaymentData.value.City,
  Country_Region_Code: currentPaymentData.value.Country_Region_Code,
  IBAN: currentPaymentData.value.IBAN
})
let dataValid = ref({
  Payment_Form: false,
  Name: false,
  Post_Code: false,
  City: false,
  Country_Region_Code: false,
  IBAN: false
})

const dataChanged = computed(() => {
  return (paymentBaseData.value.Payment_Form !== currentPaymentData.value.Payment_Form)
    || (paymentBaseData.value.Name !== currentPaymentData.value.Name)
    || (paymentBaseData.value.Post_Code !== currentPaymentData.value.Post_Code)
    || (paymentBaseData.value.City !== currentPaymentData.value.City)
    || (paymentBaseData.value.Country_Region_Code !== currentPaymentData.value.Country_Region_Code)
    || (paymentBaseData.value.IBAN !== currentPaymentData.value.IBAN);
})

const allDataValid = computed(() => {
  return allTrue(dataValid.value);
})

const analyzeBaseData = () => {
  checkProperties(paymentBaseData);
  isSubmitted.value = false;
}

const submitBaseData = () => {
  if (allDataValid.value && dataChanged) {
    isSubmitted.value = true;
    showSuccess.value = false;
    showFailure.value = false;
    savePaymentData().then(async response => {
      if (response && response.status === 200) {
        showSuccess.value = true;
        await reloadPaymentData(paymentBaseData.value.No);
      } else {
        showFailure.value = true;
      }

      if (showSuccess.value) {
        messageStore.addInfo(new Message('info', true, false, t('contact.bankAccount.header'), '', t('contact.bankAccount.save.success'), true, 'COMPONENT'));
        isSubmitted.value = false;
        router.push({
          name: 'club-profile',
          query: {clubId: clubData.value.No}
        });
      } else {
        if (response?.response.data.errors) {
          let combinedMessage = '';
          response.response.data.errors.forEach(error => {
            combinedMessage = combinedMessage + t(error) + "\r\n";
          });
          messageStore.addError(new Message('error', true, false, t('contact.bankAccount.header'), '', combinedMessage, true, 'COMPONENT'));
        } else {
          messageStore.addError(new Message('error', true, false, t('contact.bankAccount.header'), '', t('contact.bankAccount.save.failure'), true, 'COMPONENT'));
        }
      }

    }).catch(err => {
      showFailure.value = true;
      console.log(err);
    }).finally(() => {
      isSubmitted.value = false;
      window.scrollTo(0, 0);
    });
  }
}

const savePaymentData = async () => {
  return contactStore.saveOrUpdateContactBankAccount(paymentBaseData.value).then(response => {
    return response;
  });
}

const reloadPaymentData = async (contactNumber) => {
  return contactStore.loadContactBankAccount(contactNumber);
}

const checkProperties = (obj) => {
  for (const key in obj.value) {
    if (key === 'IBAN') {
      dataValid.value[key] = validateIBAN(obj.value[key]);
    } else if (key === 'Name') {
      dataValid.value[key] = isRequired(obj.value[key]) && isNotEmptyWithinMaxLength(obj.value[key], 50);
    } else if (key === 'Post_Code') {
      dataValid.value[key] = isEmptyOrWithinMaxLength(obj.value[key], 20);
    } else if (key === 'City') {
      dataValid.value[key] = isEmptyOrWithinMaxLength(obj.value[key], 30);
    } else if (key === 'Country_Region_Code') {
      dataValid.value[key] = isSelected(obj.value[key]) || isEmpty(obj.value[key]);
    } else if (key == 'Payment_Form') {
      dataValid.value[key] = isSelected(obj.value[key]);
    }
  }
}

const allTrue = (obj) => {
  for (const o in obj) {
    if (!obj[o]) return false;
  }
  return true;
}

const isEmptyOrWithinMaxLength = (value, maxLength) => {
  return value !== undefined && value !== null && (value.trim() === '' || value.trim().length <= maxLength);
}

const isNotEmptyWithinMaxLength = (value, maxLength) => {
  return !isEmpty(value) && value.trim().length < maxLength;
}

const isRequired = (value) => {
  return !isEmpty(value) && value.trim().length > 0;
}

const isSelected = (value) => {
  return !isEmpty(value) && value.trim().length > 0;
}

const isEmpty = (value) => {
  return !value || value.trim() === '';
}

const validateIBAN = (value) => {
  let rearrange =
    value.substring(4, value.length)
    + value.substring(0, 4);
  let alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split('');
  let alphaMap = {};
  let number = [];

  alphabet.forEach((value, index) => {
    alphaMap[value] = index + 10;
  });

  rearrange.split('').forEach((value, index) => {
    number[index] = alphaMap[value] || value;
  });

  return modulo(number.join('').toString(), 97) === 1;
}

const modulo = (aNumStr, aDiv) => {
  let tmp = "";
  let i, r;
  for (i = 0; i < aNumStr.length; i++) {
    tmp += aNumStr.charAt(i);
    r = tmp % aDiv;
    tmp = r.toString();
  }
  return tmp / 1;
}


onBeforeMount(async () => {
  dataValid.value.Payment_Form = isSelected(paymentBaseData.value.Payment_Form);
  dataValid.value.Name = isRequired(paymentBaseData.value.Name) && isNotEmptyWithinMaxLength(paymentBaseData.value.Name, 50);
  dataValid.value.Post_Code = isEmptyOrWithinMaxLength(paymentBaseData.value.Post_Code, 20);
  dataValid.value.City = isEmptyOrWithinMaxLength(paymentBaseData.value.City, 30);
  dataValid.value.Country_Region_Code = isSelected(paymentBaseData.value.Country_Region_Code) || isEmpty(paymentBaseData.value.Country_Region_Code);
  dataValid.value.IBAN = validateIBAN(paymentBaseData.value.IBAN);
})

onMounted(() => {
  analyzeBaseData();
})

</script>

