<template>
  <div class="modal modal-xl fade" tabindex="-1">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          {{ $t('club.functionsAndActivities.activity.titleEdit') }}
        </div>

        <div class="modal-body">
          <div class="row">
            <div class="col-6">
              <label for="firstYear" class="form-label mt-2">{{ $t('club.functionsAndActivities.activity.yearStart') }}</label>
              <DatePicker id="firstYear" :inline="true" :showButtonBar="true" v-model="firstYear" class="form-control no-border"
                          :baseZIndex="9999" :minDate="mindate"  view="year" dateFormat="yy"></DatePicker>
            </div>
            <div class="col-6">
              <label for="lastYear" class="form-label mt-2">{{ $t('club.functionsAndActivities.activity.yearEnd') }}</label>
              <DatePicker id="lastYear" :inline="true" :showButtonBar="true" v-model="lastYear" class="form-control no-border"
                          :baseZIndex="9999" :minDate="minLastYear" view="year" dateFormat="yy"></DatePicker>
            </div>
          </div>

        </div>
        <div class="modal-footer d-flex justify-content-between">
          <DefaultButton  class="btn mt-3 btn-outline-primary " @click="saveChanges"
                          :label="$t('club.functionsAndActivities.activity.save')" />
          <DefaultButton id="member-activity-modal-cancel-button" class="btn mt-3 btn-outline-secondary " :label="$t('cancel')" data-bs-dismiss="modal"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>

import {ref, onMounted, watch, computed} from "vue";
import DatePicker from "primevue/datepicker";

const mindate = new Date();
mindate.setFullYear(1950, 0, 1);

const firstYear = ref();
const lastYear = ref();

const props = defineProps(['activity']);

onMounted(() => {
  if(props.activity) {
    if(props.activity.firstYear === 0) { // in Navision wird kein Wert als 0 gespeichert.
      firstYear.value = null;
    } else {
      const newYear = new Date();
      newYear.setFullYear(props.activity.firstYear, 0, 1);
      firstYear.value = newYear;
    }
    if(props.activity.lastYear === 0) {
      lastYear.value = null;
    } else {
      const newYear = new Date();
      newYear.setFullYear(props.activity.lastYear, 0, 1);
      lastYear.value = newYear;
    }
  }
})

watch(
  props, () => {
    if(props.activity) {
      if(props.activity.firstYear === 0) { // in Navision wird kein Wert als 0 gespeichert.
        firstYear.value = null;
      } else {
        const newYear = new Date();
        newYear.setFullYear(props.activity.firstYear, 0, 1);
        firstYear.value = newYear;
      }
      if(props.activity.lastYear === 0) {
        lastYear.value = null;
      } else {
        const newYear = new Date();
        newYear.setFullYear(props.activity.lastYear, 0, 1);
        lastYear.value = newYear;
      }
    }
  }
)

const emit = defineEmits(['memberActivitiesChanged']);
const messagesStore = useMessagesStore();
const {t} = useI18n();

const minLastYear = computed(() => {
  if(firstYear.value === null) {
    return mindate.value;
  }
  return firstYear.value;
})

async function saveChanges() {
  if(!props.activity) {
    return;
  }
  const requestPath = "/api/activity/member";

  let firstYearInt = 0;
  let lastYearInt = 0;

  if(firstYear.value !== null) {
    firstYearInt = firstYear.value.getFullYear();
  }

  if(lastYear.value !== null) {
    lastYearInt = lastYear.value.getFullYear();
  }


  const requestBody = {
    vvaId: props.activity.vvaId,
    firstYear: firstYearInt,
    lastYear: lastYearInt,
    odataEtag: props.activity.odataEtag
  }

  const response = await ApiClient.putRequest(requestPath, requestBody);

  if(response.status && response.status === 200) {
    messagesStore.addInfo(new Message('info', true, false, t('club.functionsAndActivities.activity.successEditingHeader'), "",
      t('club.functionsAndActivities.activity.successEditing')));
    document.getElementById('member-activity-modal-cancel-button').click();
    emit('memberActivitiesChanged');
  } else if(response.status && response.status === 404) {
    messagesStore.addError(new Message('error', true, false, t('club.functionsAndActivities.activity.editNotFoundHeader'), "",
      t('club.functionsAndActivities.activity.editNotFound')));
  } else {
    messagesStore.addError(new Message('error', true, false, t('errorMessages.generic_error'), "", t('errorMessages.generic_error')));
  }

}

import DefaultButton from "@/components/ui/DefaultButton.vue";
import ApiClient from "@/api/ApiClient";
import Message from "@/helpers/message";
import {useMessagesStore} from "@/store/modules/messages/messages";
import {useI18n} from "vue-i18n";
</script>

<style scoped>
.p-datepicker :deep(.p-datepicker-title)
 {
  display: none;
}

.no-border {
  border: none !important;
  padding: 0;
}

</style>
