<template>

  <div class="modal modal-xl fade" tabindex="-1" >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          {{ props.createNew ? $t('club.functionsAndActivities.activity.titleNew') : $t('club.functionsAndActivities.activity.titleEdit') }}
        </div>

        <div class="modal-body">
          <div>
            <div class="row">
              <div class="col-6">
                <label for="description" class="form-label mt-2">{{ $t('club.functionsAndActivities.activity.description') }}</label>
                <input type="text" id="description" class="form-control" :placeholder="$t('club.functionsAndActivities.activity.description')"
                       v-model="newDescription"  autofocus/>
              </div>
              <div class="col-6">
                <label for="pointsPerYear" class="form-label mt-2">{{ $t('club.functionsAndActivities.activity.pointsEachYear') }}</label>
                <input type="number" id="pointsPerYear" class="form-control" :placeholder="$t('club.functionsAndActivities.activity.pointsEachYear')"
                       v-model="newPointsPerYear" />
              </div>
            </div>
          </div>


        </div>
        <div class="modal-footer d-flex justify-content-between">
          <DefaultButton v-if="!props.createNew" class="btn mt-3 btn-outline-primary "
                         :label="$t('club.functionsAndActivities.activity.save')" @click="saveEdit()" />
          <DefaultButton v-if="props.createNew" class="btn mt-3 btn-outline-primary "
                         :label="$t('club.functionsAndActivities.activity.save')" @click="saveNew()" />

          <DefaultButton id="modal-cancel-button" class="btn mt-3 btn-outline-secondary " :label="$t('cancel')" data-bs-dismiss="modal"/>
        </div>
      </div>
    </div>
  </div>

</template>

<script setup>
import DefaultButton from "@/components/ui/DefaultButton.vue";
import {computed, onMounted, ref, watch} from "vue";
import {useMessagesStore} from "@/store/modules/messages/messages.js";
import {required} from "@vuelidate/validators";
import {useVuelidate} from "@vuelidate/core";
import ApiClient from "@/api/ApiClient";
import Message from "@/helpers/message";
import {useI18n} from "vue-i18n";
const {t} = useI18n();


const messagesStore = useMessagesStore();

const newDescription = ref("");
const newPointsPerYear = ref("");

const props = defineProps(['activity', 'createNew']);

const rules = computed(() => {
  return {
    newDescription: {required},
    newPointsPerYear: {required}
  }
})

const $v = useVuelidate(rules, {newDescription, newPointsPerYear});
const emit = defineEmits(['clubActivitiesChanged']);

async function saveNew() {
  if ($v.value.$invalid) {
    return;
  }
  const request = "/api/activity/club";
  const requestBody = {
    clubNo: props.activity.clubNo,
    description: newDescription.value,
    pointsEachYear: newPointsPerYear.value
  }

  const response = await ApiClient.postRequestWithAuthorization(request, requestBody);

  if (response.data && response.data.error) {
    messagesStore.addError(new Message('error', true, false, t('errorMessages.generic_error'), "", response.data.error.message));
  } else if (response && response.status === 200) {
    messagesStore.addInfo(new Message('info', true, false,
      t('club.functionsAndActivities.activity.successCreatingNewHeader'), "", t('club.functionsAndActivities.activity.successCreatingNew')))
    emit("clubActivitiesChanged");
  } else {
    messagesStore.addError(new Message('error', true, false, t('errorMessages.generic_error'), "", t('errorMessages.generic_error')));
  }

  document.getElementById('modal-cancel-button').click();

}

async function saveEdit() {
  if ($v.value.$invalid) {
    return;
  }

  const request = "/api/activity/club";
  const requestBody = {
    odataEtag: props.activity.odataEtag,
    clubNo: props.activity.clubNo,
    code: props.activity.code,
    description: newDescription.value,
    pointsEachYear: newPointsPerYear.value
  }

  const response = await ApiClient.putRequest(request, requestBody);

  if (response.data && response.data.error) {
    messagesStore.addError(new Message('error', true, false, t('errorMessages.generic_error'), "", response.data.error.message));
  } else if (response && response.status === 200) {
    messagesStore.addInfo(new Message('info', true, false,
      t('club.functionsAndActivities.activity.successEditingHeader'), "", t('club.functionsAndActivities.activity.successEditing')))
    emit("clubActivitiesChanged");
  } else {
    messagesStore.addError(new Message('error', true, false, t('errorMessages.generic_error'), "", t('errorMessages.generic_error')));
  }

  document.getElementById('modal-cancel-button').click();

}




onMounted(() => {
  if(props.activity) {
    newDescription.value = props.activity.description;
    newPointsPerYear.value = props.activity.pointsEachYear;
  }
})

watch(
  props, () => {
    if(props.activity) {
      newDescription.value = props.activity.description;
      newPointsPerYear.value = props.activity.pointsEachYear;
    }
  }
)

</script>
