<template>
  <club-layout :is-loading="false" :title="clubData.status === 200 ? $t('club.squads') + ' bearbeiten' : $t('club.notFound.title')">
    <template #content v-if="clubData.status === 200">
      <h6 class="mb-4 mx-5">{{ $t('club.squads') + ': ' + clubData.data.Name }} </h6>
      <div class="row">
        <div class="col">

      <DataTable :value="squadData.squads"
                 :sort-field="sortColumn" :sort-order="1" @sort="onSort"
                 data-key="id"
                 v-model:filters="filters" filter-display="row"
                 :loading="isLoading"
                 ref="dt" :export-filename="$t('institutes.list.title').replaceAll(' ', '_')"
                 row-hover
                 :paginator="squadData.squads.length > 50" :rows="50" :rows-per-page-options="[50, 100, 150, 200]"
                 paginatorTemplate="CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink JumpToPageDropdown"
                 :currentPageReportTemplate="`{first}` + ' ' + $t('section.clubMembers.pagination.to') + ' ' + `{last}` + ' ' + $t('section.clubMembers.pagination.of') + ' ' +  `{totalRecords}`"
                 class="border-bottom">
        <template #empty>
          <div class="alert alert-info">{{ $t('institutes.list.noInstitutesFound') }}</div>
        </template>
        <template #paginatorfirstpagelinkicon>
          <i class="bi bi-arrow-bar-left"/>
        </template>
        <template #paginatorprevpagelinkicon>
          <i class="bi bi-arrow-left"/>
        </template>
        <template #paginatornextpagelinkicon>
          <i class="bi bi-arrow-right"/>
        </template>
        <template #paginatorlastpagelinkicon>
          <i class="bi bi-arrow-bar-right"/>
        </template>

        <Column field="id" header="No">
          <template #body="{data}">
            {{ data.No }}
          </template>
        </Column>

        <Column field="description_de" header="Name Deutsch">
          <template #body="{data}">
            {{ data.Description }}
          </template>
        </Column>

        <Column field="description_fr" header="Name Französisch">
          <template #body="{data}">
            {{ data.DescriptionFR }}
          </template>
        </Column>

        <Column field="description_it" header="Name Italienisch">
          <template #body="{data}">
            {{ data.DescriptionIT }}
          </template>
        </Column>
        <Column>
          <template #body="{data}">
            <div class="btn-group btn-group-sm">
              <Button :title="$t('institutes.edit.title', {groupName: data.Description})" unstyled class="btn btn-outline-dark mx-1" :data-bs-whatever="JSON.stringify(data)" data-bs-target="#editSquadModal" data-bs-toggle="modal">
                <i class="bi bi-pencil"></i>
              </Button>
              <Button :title="$t('institutes.delete.title', {groupName: data.Description})" unstyled class="btn btn-outline-primary" data-bs-target="#deleteSquadModal" data-bs-toggle="modal" type="button">
                <i class="bi bi-trash"></i>
              </Button>
            </div>
          </template>
        </Column>

      </DataTable>
        </div>
      </div>
      <div class="row-12">

            <div id="editSquadModal" aria-hidden="false" aria-labelledby="editSquadModal" class="modal fade"
                 tabindex="-1">
              <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                  <div class="modal-header">
                    <h1 id="exampleModalLabel" class="modal-title fs-5">Riege bearbeiten</h1>
                    <button aria-label="Close" class="btn-close" data-bs-dismiss="modal" type="button"></button>
                  </div>
                  <div class="modal-body">
                    <form>
                      <div class="mb-3">
                        <label class="col-form-label" for="quad-no">No:</label>
                        <input id="quad-no" class="form-control text-muted" type="text" readonly>
                      </div>
                      <div class="mb-3">
                        <label class="col-form-label" for="quad-no">Name Deutsch:</label>
                        <input id="quad-name" class="form-control" type="text">
                      </div>
                      <div class="mb-3">
                        <label class="col-form-label" for="quad-name-fr">Name Französisch:</label>
                        <input id="quad-name-fr" class="form-control" type="text">
                      </div>
                      <div class="mb-3">
                        <label class="col-form-label" for="quad-name-it">Name Italienisch:</label>
                        <input id="quad-name-it" class="form-control" type="text">
                      </div>
                    </form>
                  </div>
                  <div class="modal-footer">
                    <Button class="btn btn-secondary" data-bs-dismiss="modal" type="button">Cancel</Button>
                    <Button class="btn btn-primary" data-bs-dismiss="modal" type="button"
                            @click="saveSquadChanges(clubData.data.No)">Speichern
                    </Button>
                  </div>
                </div>
              </div>
            </div>

            <div class="modal fade" id="deleteSquadModal" tabindex="-1" role="dialog" aria-labelledby="deleteSquadModal" aria-hidden="false">
              <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                  <div class="modal-header">
                    <h1 class="modal-title" id="exampleModalLongTitle">Riege Löschen</h1>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div class="modal-body">
                    Es sind noch Mitglieder mit dieser Riege verbunden.
                    Es können nur Riegen gelöscht werden, auf der keine Mitglieder registriert sind.
                  </div>
                  <div class="modal-footer">
                    <Button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</Button>
                    <Button type="button" class="btn btn-primary" disabled>Delete</Button>
                  </div>
                </div>
              </div>
            </div>

          </div>
        <div class="row w-100 flex-center mb-3 mt-3">
          <Button title="Export" label="Export" @click="exportCSV($event)" class="datatable-export me-1 width-auto" severity="secondary" outlined>
            <i class="bi bi-filetype-csv"></i>
          </Button>
          <Button title="Neue Riege hinzufügen"  data-bs-toggle="modal" data-bs-target="#addNewMemberModal" class="me-1 width-auto" severity="secondary" outlined >
            <i class="bi bi-plus-circle" ></i>
          </Button>
          <AddNewSquadModal id="addNewMemberModal" :clubId="currentClubId" />
        </div>
      <BootstrapToast/>
    </template>
    <template #content v-else>
      <NoClubFoundAlert/>
    </template>


  </club-layout>
</template>


<script setup>

import BootstrapToast from "@/components/ui/BootstrapToast.vue";
import ClubLayout from "@/components/club/ClubLayout";
import {useSquadStore} from "@/store/modules/squad/squad.js";

import NoClubFoundAlert from "@/components/ui/alert/NoClubFoundAlert.vue";
import Button from "primevue/button";
import {useClubStore} from "@/store/modules/club/club";
import {computed, ref, onMounted} from "vue";
import AddNewSquadModal from "@/components/ui/AddNewSquadModal.vue";

const clubStore = useClubStore();
const clubData = computed(() => clubStore.getClubData);

const squadStore = useSquadStore();
const squadData = computed(() => squadStore.getSquadData);

const sortColumn = ref('Institute_No');
const dt = ref();
const onSort = (event) => {
  return sortColumn.value = event.sortField;
};

const exportCSV = () => {
  dt.value.exportCSV();
}

const saveSquadChanges = (clubId) => {
  const exampleModal = document.getElementById('editSquadModal');
  const ret = {}
  const modalNoInput = exampleModal.querySelector('#quad-no');
  ret.No = modalNoInput.value;
  const modalDescriptionInput = exampleModal.querySelector('#quad-name');
  ret.Description = modalDescriptionInput.value;
  const modalDescriptionFrInput = exampleModal.querySelector('#quad-name-fr');
  ret.DescriptionFR = modalDescriptionFrInput.value;
  const modalDescriptionItInput = exampleModal.querySelector('#quad-name-it');
  ret.DescriptionIT=modalDescriptionItInput.value;
  ret.Club_No=clubId;
  const quadstore = useSquadStore();
  quadstore.saveSquadByNo(JSON.stringify(ret));

}

onMounted(() => {
  const quadModal = document.getElementById('editSquadModal');
      if (quadModal) {

        quadModal.addEventListener('show.bs.modal', event => {
        const button = event.relatedTarget;
        const quad = JSON.parse(button.getAttribute('data-bs-whatever'));
        const modalTitle = quadModal.querySelector('.modal-title');
        const modalNoInput = quadModal.querySelector('#quad-no');
        const modalDescriptionInput = quadModal.querySelector('#quad-name');
        const modalDescriptionFrInput = quadModal.querySelector('#quad-name-fr');
        const modalDescriptionItInput = quadModal.querySelector('#quad-name-it');
        modalTitle.textContent = `Riege bearbeiten: ${quad.No}`;
        modalNoInput.value = quad.No;
        modalDescriptionInput.value = quad.Description;
        modalDescriptionFrInput.value = quad.DescriptionFR;
        modalDescriptionItInput.value = quad.DescriptionIT;
      })
    }

})

</script>

<style>

</style>


