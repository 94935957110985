<template>
  <div class="row mb-3">
    <div class="col">
      <DataTable :value="honors"
                  scrollable scroll-height="60vh"
                  :sort-field="sortColumn" :sort-order="1" @sort="onSort"
                  data-key="honorId"
                  v-model:filters="filters" filter-display="row"
                  ref="dt"
                  :loading="isLoading"
                  paginator :rows="50" :rows-per-page-options="[50, 100, 150, 200]"
                  paginatorTemplate="CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink JumpToPageDropdown"
                  :currentPageReportTemplate="`{first}` + ' ' + $t('pagination.to') + ' ' + `{last}` + ' ' + $t('pagination.of') + ' ' +  `{totalRecords}`"
                  csv-separator=";" :rowHover="true" :alwaysShowPaginator="false"
                  exportFilename="honors">

        <template #empty>
          <div class="alert alert-info">{{ $t('club.member.honors.notFound') }}</div>
        </template>
        <template #paginatorfirstpagelinkicon>
          <i class="bi bi-arrow-bar-left"/>
        </template>
        <template #paginatorprevpagelinkicon>
          <i class="bi bi-arrow-left"/>
        </template>
        <template #paginatornextpagelinkicon>
          <i class="bi bi-arrow-right"/>
        </template>
        <template #paginatorlastpagelinkicon>
          <i class="bi bi-arrow-bar-right"/>
        </template>

        <Column field="description" filter-field="description" filter-header-class="table-header-filter" :show-filter-menu="false" :sortable="true" :header-class="(sortColumn === 'description' ? 'tableHeader text-primary' : 'tableHeader')" :header="$t('club.member.honors.honor')">
          <template #body="{data}">
            {{ data.description }}
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText v-model="filterModel.value" type="text" @input="filterCallback()" :placeholder="$t('section.clubMembers.filter', {filter: $t('club.member.honors.honor')})" class="form-control input-filter"/>
          </template>
        </Column>

        <Column field="dateOfHonors" filter-field="dateOfHonors" filter-header-class="table-header-filter" :show-filter-menu="false" :sortable="true" :header-class="(sortColumn === 'dateOfHonors' ? 'tableHeader text-primary' : 'tableHeader')" :header="$t('club.member.honors.date')">
          <template #body="{data}">
            {{ formatDate(data.dateOfHonors) }}
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText v-model="filterModel.value" type="text" @input="filterCallback()" :placeholder="$t('section.clubMembers.filter', {filter: $t('club.member.honors.date')})" class="form-control input-filter"/>
          </template>
        </Column>

      </DataTable>
    </div>
  </div>

  <div class="row w-100 flex-center mb-3 mt-3">
    <Button :title="$t('export')" :label="$t('export')" @click="exportCSV($event)" class="btn btn-outline-primary datatable-export me-1 width-auto" unstyled>
      <i class="bi bi-filetype-csv"></i>
    </Button>
  </div>
  
  <BootstrapToast/>
</template>
  
<script setup>
  
  import Button from "primevue/button";
  
  import {computed, ref} from "vue";
  import {useClubMemberStore} from "@/store/modules/clubMember/clubMember";
  import {FilterMatchMode} from '@primevue/core/api';
  import BootstrapToast from "@/components/ui/BootstrapToast.vue";
  
  const clubMemberStore = useClubMemberStore();
  
  const dt = ref();
  const sortColumn = ref('description');
  const filters = ref({
    'description': {value: null, matchMode: FilterMatchMode.CONTAINS},
    'dateOfHonors': {value: null, matchMode: FilterMatchMode.CONTAINS},
  });
  
  const isLoading = computed(() => {
    return !(honors.value);
  });
  
  const honors = computed(() => {

    return clubMemberStore.getHonors
  });
  
  const onSort = (event) => {
    sortColumn.value = event.sortField;
  };
  
  const exportCSV = () => {
    dt.value.exportCSV({selectionOnly: false})
  };
  
</script>
  
  