<template>
  <div class="row border-bottom pb-4 mb-4">
    <div class="col">
      <div class="row mb-3">
        <div class="col-12">
          <p class="mb-2"><strong>{{ $t('club.member.stvNumber') }}</strong></p>
          <p>
            {{ memberBaseData.memberId }}<br>
          </p>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-6">
          <p class="mb-2"><strong>{{ $t('club.member.socialInsuranceNr') }}</strong></p>
          <InputMask id="social-insurance-nr" v-model="memberBaseData.socialInsuranceNr" mask="756.9999.9999.99" placeholder="756.0000.0000.00" :aria-label="$t('club.member.socialInsuranceNr')"></InputMask>
          <PrimeMessage severity="error" icon="bi bi-x-circle" v-show="!dataValid.socialInsuranceNr">{{ $t('club.member.validation.emptyOrExceeded', [$t('club.member.socialInsuranceNr'), maxLength16]) }}</PrimeMessage>
        </div>
        <div class="col-6">
          <p class="mb-2"><strong>{{ $t('club.member.swissAthleticsNr') }}</strong></p>
          <InputMask id="swiss-athletics-nr" v-model="memberBaseData.swissAthleticsNr" mask="999?999" placeholder="000000" :aria-label="$t('club.member.swissAthleticsNr')"></InputMask>
          <PrimeMessage severity="error" icon="bi bi-x-circle" v-show="!dataValid.swissAthleticsNr">{{ $t('club.member.validation.emptyOrShortOrExceeded', [$t('club.member.swissAthleticsNr'), minLength03, maxLength10]) }}</PrimeMessage>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-6">
          <p class="mb-2"><strong>{{ $t('club.member.firstName') }} *</strong></p>
          <InputText v-model="memberBaseData.firstName" :invalid="!dataValid.firstName" :aria-label="$t('club.member.firstName')" @update:modelValue="analyzeBaseData" class="mb-1 w-100" />
          <PrimeMessage severity="error" icon="bi bi-x-circle" v-show="!dataValid.firstName">{{ $t('club.member.validation.emptyOrExceeded', [$t('club.member.firstName'), maxLength30]) }}</PrimeMessage>
        </div>
        <div class="col-6">
          <p class="mb-2"><strong>{{ $t('club.member.surname') }} *</strong></p>
          <InputText v-model="memberBaseData.surname" :invalid="!dataValid.surname" :aria-label="$t('club.member.surname')" @update:modelValue="analyzeBaseData" class="mb-1 w-100"/>
          <PrimeMessage severity="error" icon="bi bi-x-circle" v-show="!dataValid.surname">{{ $t('club.member.validation.emptyOrExceeded', [$t('club.member.surname'), maxLength50]) }}</PrimeMessage>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-6">
          <p class="mb-2"><strong>{{ $t('club.member.salutation') }} *</strong></p>
          <Select v-model="memberBaseData.salutation" :invalid="!dataValid.salutation" :options="salutationOptions" optionLabel="name" optionValue="code" checkmark :aria-label="$t('club.member.salutation')" @update:modelValue="analyzeBaseData" class="mb-1 w-100" />
          <PrimeMessage severity="error" icon="bi bi-x-circle" v-show="!dataValid.salutation">{{ $t('club.member.validation.invalidSelection', [$t('club.member.salutation'), maxLength30]) }}</PrimeMessage>
        </div>
        <div class="col-6">
          <p class="mb-2"><strong>{{ $t('club.member.birthday') }} *</strong></p>
          <DatePicker id="birthday" v-model="memberBaseData.birthday" :invalid="!dataValid.birthday" dateFormat="dd.mm.yy" :aria-label="$t('club.member.swissAthleticsNr')" showIcon class="mb-1 w-100" :touchUI="true"></DatePIcker>
          <PrimeMessage severity="error" icon="bi bi-x-circle" v-show="!dataValid.birthday">{{ $t('club.member.validation.invalidFormat', [$t('club.member.birthday')]) }}</PrimeMessage>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-6">
          <p class="mb-2"><strong>{{ $t('club.member.language') }} *</strong></p>
          <Select v-model="memberBaseData.language" :invalid="!dataValid.language" :options="languageOptions" optionLabel="name" optionValue="code" checkmark :aria-label="$t('club.member.language')" @update:modelValue="analyzeBaseData" class="mb-1 w-100" />
          <PrimeMessage severity="error" icon="bi bi-x-circle" v-show="!dataValid.language">{{ $t('club.member.validation.invalidSelection', [$t('club.member.language')]) }}</PrimeMessage>
        </div>
        <div class="col-6">
          <p class="mb-2"><strong>{{ $t('club.member.dateOfDeath') }}</strong></p>
          <DatePicker id="dateOfDeath" v-model="memberBaseData.dateOfDeath" :invalid="!dataValid.dateOfDeath" dateFormat="dd.mm.yy" :aria-label="$t('club.member.dateOfDeath')" showIcon class="mb-1 w-100" :touchUI="true"></DatePIcker>
          <PrimeMessage severity="error" icon="bi bi-x-circle" v-show="!dataValid.dateOfDeath">{{ $t('club.member.validation.invalidSelection', [$t('club.member.dateOfDeath')]) }}</PrimeMessage>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-12">
          <p class="mb-2"><strong>{{ $t('club.member.release') }}</strong></p>
          <p class="text-muted"><em>{{ $t('club.member.releaseInfo') }}</em></p>
          <InputMask id="release" v-model="memberBaseData.releaseMyStvFSG" mask="999?9999" placeholder="0000000" :aria-label="$t('club.member.release')" :invalid="!dataValid.releaseMyStvFSG" @update:modelValue="analyzeBaseData"></InputMask>
          <PrimeMessage severity="error" icon="bi bi-x-circle" v-show="!dataValid.releaseMyStvFSG">{{ $t('club.member.validation.releaseMySTVFSG', [$t('club.member.release')]) }}</PrimeMessage>
        </div>
      </div>

      <div class="row mt-5">
        <div class="col-12">
          <h5 class="mb-3">{{ $t('club.member.titles.address') }}</h5>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-6">
          <p class="mb-2"><strong>{{ $t('club.member.postCode') }} *</strong></p>
          <AutoComplete v-model="memberBaseData.postCode" optionLabel="ZipCode" :suggestions="autocompleteZipPlaceSelection" @complete="autocompletePlace" @option-select="applyPlace" inputClass="mb-1 w-100" :completeOnFocus=true :minLength=2>
            <template #option="slotProps">
              <div class="flex items-center">
                <div>{{ slotProps.option.ZipCode }} {{ slotProps.option.TownName }}</div>
              </div>
            </template>
          </AutoComplete>
          <PrimeMessage severity="error" icon="bi bi-x-circle" v-show="!dataValid.postCode">{{ $t('club.member.validation.emptyOrExceeded', [$t('club.member.postCode'), maxLength20]) }}</PrimeMessage>
        </div>
        <div class="col-6">
          <p class="mb-2"><strong>{{ $t('club.member.city') }} *</strong></p>
          <AutoComplete v-model="memberBaseData.city" optionLabel="ZipCode" :suggestions="autocompleteZipPlaceSelection" @complete="autocompletePlace" @option-select="applyPlace" inputClass="mb-1 w-100" :completeOnFocus=true :minLength=2>
            <template #option="slotProps">
              <div class="flex items-center">
                <div>{{ slotProps.option.ZipCode }} {{ slotProps.option.TownName }}</div>
              </div>
            </template>
          </AutoComplete>
          <PrimeMessage severity="error" icon="bi bi-x-circle" v-show="!dataValid.city">{{ $t('club.member.validation.emptyOrExceeded', [$t('club.member.city'), maxLength30]) }}</PrimeMessage>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-6">
          <p class="mb-2"><strong>{{ $t('club.member.address') }} *</strong></p>
          <AutoComplete v-model="memberBaseData.address" optionLabel="StreetName" :suggestions="autocompleteSelection" @complete="autocompleteAddress" @option-select="applyAddress" inputClass="mb-1 w-100" :completeOnFocus=true :minLength=2>
            <template #option="slotProps">
              <div class="flex items-center">
                <div>{{ slotProps.option.StreetName }} {{ slotProps.option.HouseNo }}{{ slotProps.option.HouseNoAddition }}, {{ slotProps.option.ZipCode }} {{ slotProps.option.TownName }}</div>
              </div>
            </template>
          </AutoComplete>
          <PrimeMessage severity="error" icon="bi bi-x-circle" v-show="!dataValid.address">{{ $t('club.member.validation.emptyOrExceeded', [$t('club.member.address'), maxLength50]) }}</PrimeMessage>
        </div>
        <div class="col-6">
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-6">
          <p class="mb-2"><strong>{{ $t('club.member.addressAddition') }}</strong></p>
          <InputText v-model="memberBaseData.addressAddition" :invalid="!dataValid.addressAddition" :aria-label="$t('club.member.addressAddition')" @update:modelValue="analyzeBaseData" class="mb-1 w-100" />
          <PrimeMessage severity="error" icon="bi bi-x-circle" v-show="!dataValid.addressAddition">{{ $t('club.member.validation.emptyOrExceeded', [$t('club.member.addressAddition'), maxLength50]) }}</PrimeMessage>
        </div>
        <div class="col-6">
          <p class="mb-2"><strong>{{ $t('club.member.addressAddition2') }}</strong></p>
          <InputText v-model="memberBaseData.addressAddition2" :invalid="!dataValid.addressAddition2" :aria-label="$t('club.member.addressAddition2')" @update:modelValue="analyzeBaseData" class="mb-1 w-100"/>
          <PrimeMessage severity="error" icon="bi bi-x-circle" v-show="!dataValid.addressAddition2">{{ $t('club.member.validation.emptyOrExceeded', [$t('club.member.addressAddition2'), maxLength50]) }}</PrimeMessage>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-6">
          <p class="mb-2"><strong>{{ $t('club.member.county') }}</strong></p>
          <Select v-if="i18nLocale === 'fr'" v-model="memberBaseData.county" :invalid="!dataValid.county" :options="counties" optionLabel="nameFr" optionValue="code" checkmark :aria-label="$t('club.member.county')" @update:modelValue="analyzeBaseData" class="mb-1 w-100" />
          <Select v-else v-model="memberBaseData.county" :invalid="!dataValid.county" :options="counties" optionLabel="nameDe" optionValue="code" checkmark :aria-label="$t('club.member.county')" @update:modelValue="analyzeBaseData" class="mb-1 w-100" />
          <PrimeMessage severity="error" icon="bi bi-x-circle" v-show="!dataValid.county">{{ $t('club.member.validation.invalidSelection', [$t('club.member.county')]) }}</PrimeMessage>
        </div>
        <div class="col-6">
          <p class="mb-2"><strong>{{ $t('club.member.country') }}</strong></p>
          <Select v-model="memberBaseData.countryCode" :invalid="!dataValid.countryCode" :options="countries" optionLabel="name" optionValue="code" :aria-label="$t('club.member.country')" @update:modelValue="analyzeBaseData" class="mb-1 w-100" :placeholder="$t('club.member.country')">
            <template #value="slotProps">
              <div v-if="slotProps.value" class="flex items-center">
                  <img v-if="i18nLocale === 'fr'" :alt="findCountry(slotProps.value).nameDe" src="https://primefaces.org/cdn/primevue/images/flag/flag_placeholder.png" :class="`vti__flag ${slotProps.value.toLowerCase()}`" />
                  <img v-else :alt="findCountry(slotProps.value).nameFr" src="https://primefaces.org/cdn/primevue/images/flag/flag_placeholder.png" :class="`vti__flag ${slotProps.value.toLowerCase()}`" />
                  <div v-if="i18nLocale === 'fr'">{{ findCountry(slotProps.value).nameFr }}</div>
                  <div v-else>{{ findCountry(slotProps.value).nameDe }}</div>
              </div>
              <span v-else>
                  {{ slotProps.placeholder }}
              </span>
          </template>
          <template #option="slotProps">
              <div class="flex items-center" v-if="i18nLocale === 'fr'">
                  <img :alt="slotProps.option.nameFr" src="https://primefaces.org/cdn/primevue/images/flag/flag_placeholder.png" :class="`vti__flag ${slotProps.option.code.toLowerCase()}`" />
                  <div>{{ slotProps.option.nameFr }}</div>
              </div>
              <div class="flex items-center" v-else>
                  <img :alt="slotProps.option.nameDe" src="https://primefaces.org/cdn/primevue/images/flag/flag_placeholder.png" :class="`vti__flag ${slotProps.option.code.toLowerCase()}`" />
                  <div>{{ slotProps.option.nameDe }}</div>
              </div>
          </template>
          </Select>
          <PrimeMessage severity="error" icon="bi bi-x-circle" v-show="!dataValid.countryCode">{{ $t('club.member.validation.invalidSelection', [$t('club.member.country')]) }}</PrimeMessage>
        </div>
        <div class="col-6">

        </div>
      </div>

      <div class="row mt-5">
        <div class="col-12">
          <h5 class="mb-3">{{ $t('club.member.titles.communication') }}</h5>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <p class="mb-2"><strong>{{ $t('club.member.phonePrivate') }}</strong></p>
          <vue-tel-input v-model="memberBaseData.phonePrivate" mode="international" :required=true @validate="validatePhonePrivate" class="mb-1"></vue-tel-input>
          <PrimeMessage severity="error" icon="bi bi-x-circle" v-show="!dataValid.phonePrivate && memberBaseData.phonePrivate">{{ $t('club.member.validation.invalidNumber', [$t('club.member.phonePrivate')]) }}</PrimeMessage>
        </div>
        <div class="col-6">
          <p class="mb-2"><strong>{{ $t('club.member.phoneBusiness') }}</strong></p>
          <vue-tel-input v-model="memberBaseData.phoneBusiness" mode="international" @validate="validatePhoneBusiness" class="mb-1"></vue-tel-input>
          <PrimeMessage severity="error" icon="bi bi-x-circle" v-show="!dataValid.phoneBusiness && memberBaseData.phoneBusiness">{{ $t('club.member.validation.invalidNumber', [$t('club.member.phoneBusiness')]) }}</PrimeMessage>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-6">
          <p class="mb-2"><strong>{{ $t('club.member.phoneMobile') }}</strong></p>
          <vue-tel-input v-model="memberBaseData.phoneMobile" mode="international" @validate="validatePhoneMobile" class="mb-1"></vue-tel-input>
          <PrimeMessage severity="error" icon="bi bi-x-circle" v-show="!dataValid.phoneMobile && memberBaseData.phoneMobile">{{ $t('club.member.validation.invalidNumber', [$t('club.member.phoneMobile')]) }}</PrimeMessage>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-6">
          <p class="mb-2"><strong>{{ $t('club.member.emailPrivate') }}</strong></p>
          <MailInputProfile v-model.lowercase="memberBaseData.emailPrivate" :label="$t('club.member.emailPrivate')" id="email-private" @on-change="onMailPrivateChange" :validationFailed="!dataValid.emailPrivate" :validationMessage="dataValidMessage.emailPrivate"></MailInputProfile>
        </div>
        <div class="col-6">
          <p class="mb-2"><strong>{{ $t('club.member.emailAlternative') }}</strong></p>
          <MailInputProfile v-model.lowercase="memberBaseData.emailAlternative" :label="$t('club.member.emailAlternative')" id="email-alternative" @on-change="onMailAlternativeChange" :validationFailed="!dataValid.emailAlternative" :validationMessage="dataValidMessage.emailAlternative"></MailInputProfile>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-6">
          <p class="mb-2"><strong>{{ $t('club.member.emailVerein') }}</strong></p>
          <MailInputProfile v-model.lowercase="memberBaseData.emailVerein" :label="$t('club.member.emailVerein')" id="email-club" @on-change="onMailClubChange" :validationFailed="!dataValid.emailVerein" :validationMessage="dataValidMessage.emailVerein"></MailInputProfile>
        </div>
      </div>
    </div>
  </div>
  <div class="row border-bottom pb-4 mb-4">
    <div class="col">
      <h5 class="mb-3">{{ $t('club.member.titles.subscriptions') }}</h5>
      <div class="row mb-3">
        <div class="col-12">
          <p class="mb-2"><strong>{{ $t('club.member.subscriptions.addressRelease') }}</strong></p>
          <Checkbox v-model="memberBaseData.addressRelease" inputId="address-release" :binary="true" :trueValue="true" :falseValue="false" @update:modelValue="analyzeBaseData"></Checkbox>
          <label for="address-release">{{ $t('club.member.subscriptions.addressReleaseLabel') }}</label>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-12">
          <p class="mb-2"><strong>{{ $t('club.member.subscriptions.advertising') }}</strong></p>
          <Checkbox v-model="memberBaseData.advertising" inputId="advertising" :binary="true" :trueValue="true" :falseValue="false" @update:modelValue="analyzeBaseData"></Checkbox>
          <label for="advertising">{{ $t('club.member.subscriptions.advertisingLabel') }}</label>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-12">
          <p class="mb-2"><strong>{{ $t('club.member.subscriptions.alderEisenhutCatalog') }}</strong></p>
          <Checkbox v-model="memberBaseData.noAlderEisenhutCatalog" inputId="alder-eisenhut" :binary="true" @update:modelValue="analyzeBaseData" disabled></Checkbox>
          <label for="alder-eisenhut">{{ $t('club.member.subscriptions.alderEisenhutCatalogLabel') }}</label>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-12">
          <p class="mb-2"><strong>{{ $t('club.member.subscriptions.gymLive') }}</strong></p>
          <Checkbox v-model="memberBaseData.gymLiveAbo" inputId="gym-live" :binary="true" @update:modelValue="analyzeBaseData"></Checkbox>
          <label for="gym-live">{{ $t('club.member.subscriptions.gymLiveLabel') }}</label>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-12">
          <p class="mb-2"><strong>{{ $t('club.member.subscriptions.regional') }}</strong></p>
          <Checkbox v-model="memberBaseData.regionalAbo" inputId="regional" :binary="true" @update:modelValue="analyzeBaseData"></Checkbox>
          <label for="regional">{{ $t('club.member.subscriptions.regionalLabel') }}</label>
        </div>
      </div>
    </div>
  </div>
  <div class="row border-bottom pb-4 mb-4">
    <div class="col">
      <div class="row mb-3">
        <div class="col-12">
          <p class="mb-2"><strong>{{ $t('club.member.remarks') }}</strong></p>
          <Textarea v-model="memberBaseData.mySTVComment" class="mb-1 w-100" :placeholder="$t('club.member.remarks')"></Textarea>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="d-flex gap-2">
      <DefaultButton
        class="btn-outline-dark"
        :label="$t('club.member.save.buttonText')"
        @click="submitBaseData"
        @keyup.enter="submitBaseData"
        onkeypress="submitBaseData"
        :disabled="(!allDataValid || isSubmitted) || !dataChanged"
      />
      <DefaultButton
        class="btn-outline-dark"
        :label="$t('cancel')"
        @click="cancel"
        onkeypress="cancel"
      />
    </div>
  </div>
  <BootstrapToast />

</template>

<script setup>
import {computed, onMounted, ref} from 'vue'
import {useMemberStore} from "@/store/modules/member/member.js";
import {useMessagesStore} from "@/store/modules/messages/messages.js";
import {useAutocompleteStore} from "@/store/modules/autocomplete/autocomplete.js";
import { useClubStore } from '@/store/modules/club/club.js';
import { useClubMemberStore } from '@/store/modules/clubMember/clubMember.js';
import {useRouter} from 'vue-router';
import {useI18n} from 'vue-i18n'
import { AHV13 } from 'ahv13-validator';

import MailInputProfile from "@/components/ui/MailInputProfile.vue";
import Message from "@/helpers/message.js";
import BootstrapToast from '@/components/ui/BootstrapToast.vue';
import InputText from 'primevue/inputtext';
import PrimeMessage from 'primevue/message';
import InputMask from 'primevue/inputmask';
import Select from 'primevue/select';
import DatePicker from 'primevue/datepicker';
import Textarea from 'primevue/textarea';
import Checkbox from 'primevue/checkbox';
import AutoComplete from 'primevue/autocomplete';

const emit = defineEmits(['loading', 'loadingDone'])

const router = useRouter();
const messageStore = useMessagesStore();
const memberStore = useMemberStore();
const clubStore = useClubStore();
const clubMemberStore = useClubMemberStore();
const autocompleteStore = useAutocompleteStore();
const minLengthYearInput = 4;
const maxLengthYearInput = 4;
const maxLengthInput = 80;
const minLength03 = 3;
const maxLength06 = 6;
const maxLength07 = 7;
const maxLength10 = 10;
const maxLength16 = 16;
const maxLength20 = 20;
const maxLength30 = 30;
const maxLength50 = 50;
const maxLength2000 = 2000;
const currentMemberData = computed(() => memberStore.getSingleMemberData);
const { t } = useI18n()
const countries = ref([]);
const counties = ref([]);

const addressRegex = /^([\D.s-]+[\s]?)([\d]+)?([\s]?[\D]+?)?$/;
const autocompleteSelection = ref([]);
const autocompleteZipPlaceSelection = ref([]);

const ahv13validator = new AHV13();
const i18nLocale = ref(useI18n().locale.value);

const codeMS = 'MS';
const codeMR = 'MR';
const salutationOptions = ref([
  { name: t('club.member.salutationMS'), code: codeMS },
  { name: t('club.member.salutationMR'), code: codeMR }
]);

const codeDe = 'DE';
const codeFr = 'FR';
const codeIt = 'IT';
const languageOptions = ref([
  { name: t('languages.de'), code: codeDe },
  { name: t('languages.fr'), code: codeFr },
  { name: t('languages.it'), code: codeIt }
]);

let showFailure = ref(false);
let showSuccess = ref(false);
let isSubmitted = ref(false);
// eslint-disable-next-line no-unused-vars
let memberBaseData = ref({
  socialInsuranceNr: currentMemberData.value.stvMember.socialInsuranceNr,
  swissAthleticsNr: currentMemberData.value.stvMember.swissAthleticsNr,
  firstName: currentMemberData.value.stvMember.firstName,
  surname: currentMemberData.value.stvMember.surname,
  salutation: currentMemberData.value.stvMember.salutation,
  birthday: currentMemberData.value.stvMember.birthday === null ? null : new Date(currentMemberData.value.stvMember.birthday),
  language: currentMemberData.value.stvMember.language,
  address: currentMemberData.value.stvMember.address,
  postCode: currentMemberData.value.stvMember.postCode,
  city: currentMemberData.value.stvMember.city,
  emailPrivate: currentMemberData.value.stvMember.emailPrivate,
  emailAlternative: currentMemberData.value.stvMember.emailAlternative,
  emailVerein: currentMemberData.value.stvMember.emailVerein,
  phonePrivate: currentMemberData.value.stvMember.phonePrivate,
  phoneBusiness: currentMemberData.value.stvMember.phoneBusiness,
  phoneMobile: currentMemberData.value.stvMember.phoneMobile,
  countryCode : currentMemberData.value.stvMember.countryCode,
  addressAddition: currentMemberData.value.stvMember.addressAddition,
  addressAddition2: currentMemberData.value.stvMember.addressAddition2,
  memberId: currentMemberData.value.stvMember.memberId,
  releasedMembers: currentMemberData.value.stvMember.releasedMembers,
  releaseMyStvFSG: currentMemberData.value.stvMember.releaseMyStvFSG,
  dateOfDeath: currentMemberData.value.stvMember.dateOfDeath === null ? null : new Date(currentMemberData.value.stvMember.dateOfDeath),
  county: currentMemberData.value.stvMember.county,
  mySTVComment: currentMemberData.value.stvMember.mySTVComment,
  addressRelease: currentMemberData.value.stvMember.addressRelease,
  noAlderEisenhutCatalog: currentMemberData.value.stvMember.noAlderEisenhutCatalog,
  gymLiveAbo: currentMemberData.value.stvMember.gymLiveAbo,
  regionalAbo: currentMemberData.value.stvMember.regionalAbo,
  advertising: currentMemberData.value.stvMember.advertising,
})
let dataValid = ref({
  socialInsuranceNr: false,
  swissAthleticsNr: false,
  firstName: false,
  surname: false,
  salutation: false,
  birthday: false,
  language: false,
  address: false,
  postCode: false,
  city: false,
  emailPrivate: false,
  emailAlternative: false,
  emailVerein: false,
  phonePrivate: false,
  phoneBusiness: false,
  phoneMobile: false,
  countryCode : false,
  addressAddition: false,
  addressAddition2: false,
  sex: true,
  releasedMembers: false,
  releaseMyStvFSG: false,
  dateOfDeath: false,
  county: false
})

let dataValidMessage = ref({
  emailPrivate: null,
  emailAlternative: null,
  emailVerein: null,
})

const dataChanged = computed(() => {
  return (memberBaseData.value.socialInsuranceNr !== currentMemberData.value.stvMember.socialInsuranceNr)
    || (memberBaseData.value.swissAthleticsNr !== currentMemberData.value.swissAthleticsNr)
    || (memberBaseData.value.firstName !== currentMemberData.value.firstName)
    || (memberBaseData.value.surname !== currentMemberData.value.surname)
    || (memberBaseData.value.salutation !== currentMemberData.value.salutation)
    || (memberBaseData.value.birthday !== currentMemberData.value.birthday)
    || (memberBaseData.value.language !== currentMemberData.value.language)
    || (memberBaseData.value.address !== currentMemberData.value.address)
    || (memberBaseData.value.postCode !== currentMemberData.value.postCode)
    || (memberBaseData.value.city !== currentMemberData.value.city)
    || (memberBaseData.value.emailPrivate !== currentMemberData.value.emailPrivate)
    || (memberBaseData.value.emailAlternative !== currentMemberData.value.emailAlternative)
    || (memberBaseData.value.emailVerein !== currentMemberData.value.emailVerein)
    || (memberBaseData.value.phonePrivate !== currentMemberData.value.phonePrivate)
    || (memberBaseData.value.phoneBusiness !== currentMemberData.value.phoneBusiness)
    || (memberBaseData.value.phoneMobile !== currentMemberData.value.phoneMobile)
    || (memberBaseData.value.countryCode !== currentMemberData.value.countryCode)
    || (memberBaseData.value.addressAddition !== currentMemberData.value.addressAddition)
    || (memberBaseData.value.addressAddition2 !== currentMemberData.value.addressAddition2)
    || (memberBaseData.value.memberId !== currentMemberData.value.memberId)
    || (memberBaseData.value.releasedMembers !== currentMemberData.value.releasedMembers)
    || (memberBaseData.value.releaseMyStvFSG !== currentMemberData.value.releaseMyStvFSG)
    || (memberBaseData.value.dateOfDeath !== currentMemberData.value.dateOfDeath)
    || (memberBaseData.value.county !== currentMemberData.value.county)
    || (memberBaseData.value.mySTVComment !== currentMemberData.value.mySTVComment)
    || (memberBaseData.value.addressRelease !== currentMemberData.value.addressRelease)
    || (memberBaseData.value.noAlderEisenhutCatalog !== currentMemberData.value.noAlderEisenhutCatalog)
    || (memberBaseData.value.gymLiveAbo !== currentMemberData.value.gymLiveAbo)
    || (memberBaseData.value.regionalAbo !== currentMemberData.value.regionalAbo)
    || (memberBaseData.value.advertising !== currentMemberData.value.advertising)
})

const allDataValid = computed(() => {
  return allTrue(dataValid.value);
})

const analyzeBaseData = () =>  {
  checkProperties(memberBaseData);
  isSubmitted.value = false;
}

const submitBaseData = () => {
  if (allDataValid.value && dataChanged) {
    isSubmitted.value = true;
    emit('loading');
    resetValidationMessages();
    saveMemberData().then(async response => {
      if (response && response.status === 200) {
        showSuccess.value = true;
      } else {
        showFailure.value = true;
      }

      if(showSuccess.value) {
        messageStore.addInfo(new Message('info', true, false, t('club.member.profile', {stvNr: memberBaseData.value.memberId, firstName: memberBaseData.value.firstName, surname: memberBaseData.value.surname}), '', t('club.member.save.success'), true, 'COMPONENT'));
        isSubmitted.value = false;
        router.push({
          name: 'club-members',
          query: {clubId: clubStore.getCurrentClubNo}
        });
      } else {
        if(response.data.property) {
          dataValid.value[response.data.property] = false;
          dataValidMessage.value[response.data.property] = response.data.message;
        } else {
          messageStore.addError(new Message('error', true, false, t('club.member.profile', {stvNr: memberBaseData.value.memberId, firstName: memberBaseData.value.firstName, surname: memberBaseData.value.surname}), '', t(''+response.data.message+''), true, 'COMPONENT'));
        }
        
        messageStore.addError(new Message('error', true, false, t('club.member.profile', {stvNr: memberBaseData.value.memberId, firstName: memberBaseData.value.firstName, surname: memberBaseData.value.surname}), '', t('club.member.save.failure'), true, 'COMPONENT'));
        isSubmitted.value = false;
      }

    }).catch(err => {
      showFailure.value = true;
      console.log(err);
    }).finally(() => {
      emit('loadingDone');
      isSubmitted.value = false;
      window.scrollTo(0, 0);
    });
  }
}

const saveMemberData = async () => {
  let payload = {
    releaseMemberId: memberBaseData.value.memberId,
    baseData : memberBaseData,
    clubId: clubStore.getCurrentClubNo
  };

  return clubMemberStore.saveClubMember(payload).then(response => {
    return response;
  });
}

const checkProperties = (obj) => {
  for (const key in obj.value) {
    if (key === 'Year_Established') {
      dataValid.value[key] = isEmptyOrWithinMinAndMaxLength(obj.value[key], minLengthYearInput, maxLengthYearInput);
    } else if(key === 'firstName' || key === 'city') {
      dataValid.value[key] = isNotEmptyWithinMaxLength(obj.value[key], maxLength30);
    } else if(key === 'surname' || key === 'address') {
      dataValid.value[key] = isNotEmptyWithinMaxLength(obj.value[key], maxLength50);
    } else if(key === 'socialInsuranceNr') {
      dataValid.value[key] = obj.value[key] === '' || ahv13validator.isValid(obj.value[key]);
    } else if(key === 'swissAthleticsNr') {
      dataValid.value[key] = isEmptyOrWithinMinAndMaxLength(obj.value[key], minLength03, maxLength06);
    } else if(key === 'salutation') {
      dataValid.value[key] = obj.value[key] === codeMS || obj.value[key] === codeMR;
    } else if(key === 'birthday') {
      dataValid.value[key] = isNotEmpty(obj.value[key]) && isFinite(obj.value[key]);
    } else if(key === 'language') {
      dataValid.value[key] = obj.value[key] === codeDe || obj.value[key] === codeFr || obj.value[key] === codeIt;
    } else if(key === 'releaseMyStvFSG') {
      dataValid.value[key] = isEmptyOrWithinMinAndMaxLength(obj.value[key], minLength03, maxLength07);
    } else if(key === 'countryCode') {
      dataValid.value[key] = obj.value[key] === '' || countries.value.find(c => c.code === obj.value[key]);
    } else if(key === 'county') {
      dataValid.value[key] = obj.value[key] === '' || counties.value.find(c => c.code === obj.value[key]);
    } else if(key === 'postCode') {
      dataValid.value[key] = isNotEmptyWithinMaxLength(obj.value[key], maxLength20);
    } else if(key === 'addressAddition' || key === 'addressAddition2') {
      dataValid.value[key] = isEmptyOrWithinMaxLength(obj.value[key], maxLength50);
    } else if(key === 'dateOfDeath') {
      dataValid.value[key] = obj.value[key] === null || obj.value[key] === undefined || isFinite(obj.value[key]);
    } else if(key === 'mySTVComment') {
      dataValid.value[key] = isEmptyOrWithinMaxLength(obj.value[key], maxLength2000);
    } else if(key === 'regionalAbo' || key === 'gymLiveAbo' || key === 'advertising' || key === 'noAlderEisenhutCatalog' || key === 'addressRelease') {
      dataValid.value[key] = val => 'boolean' === typeof val;
    } else {
      dataValid.value[key] = isEmptyOrWithinMaxLength(obj.value[key], maxLengthInput);
    }
  }
}

const allTrue = (obj) => {
  for (const o in obj) {
    if (!obj[o]) return false;
  }
  return true;
}

const onMailPrivateChange = (mailValidationState) => {
  dataValid.value.emailPrivate = mailValidationState;
}

const onMailAlternativeChange = (mailValidationState) => {
  dataValid.value.emailAlternative = mailValidationState;
}

const onMailClubChange = (mailValidationState) => {
  dataValid.value.emailVerein = mailValidationState;
}

const validatePhonePrivate = (event) => {
  dataValid.value.phonePrivate = event.valid ? event.valid : false
}

const validatePhoneBusiness = (event) => {
  dataValid.value.phoneBusiness = event.valid ? event.valid : false
}

const validatePhoneMobile = (event) => {
  dataValid.value.phoneMobile = event.valid ? event.valid : false
}

const isEmptyOrWithinMaxLength = (value, maxLength) => {
  return !value || (value === '' || value.length <= maxLength);
}

const isEmptyOrWithinMinAndMaxLength = (value, minLength, maxLength) => {
  return !value || (value === '' || (value.length <= maxLength && value.length >= minLength));
}

const isNotEmptyWithinMaxLength = (value, maxLength) => {
  return value && value !== '' && value.length <maxLength;
}

const isNotEmpty = (value) => {
  return value !== undefined && value !== null && value.trim !== '' ;
}

const findCountry = (country) => {
  if(countries.value !== null && countries.value.length > 0) {
    return countries.value.find(c => c.code === country)
  }
  return {};
}

const autocompleteAddress = async () => {

  let street = "";
  let streetNumber = "";
  let streetNumberAddition= "";
  if(memberBaseData.value.address && memberBaseData.value.address.length > 0) {
    let groups = memberBaseData.value.address.match(addressRegex);
    street = groups[0];
    if(groups.length > 1) {
      street = groups[1] !== undefined ? groups[1] : ""; 
      streetNumber = groups[2] !== undefined ? groups[2] : "";
      streetNumberAddition = groups[3] !== undefined ? groups[3] : "";
    }
  }
  let result = await autocompleteStore.autocompleteAddress(street, streetNumber, streetNumberAddition, memberBaseData.value.postCode, memberBaseData.value.city);
  if(result.data) {
    autocompleteSelection.value = result.data;
  } else {
    autocompleteSelection.value = [];
  }
  analyzeBaseData();
}

const autocompletePlace = async () => {

  let result = await autocompleteStore.autocompleteAddress("", "", "", memberBaseData.value.postCode, memberBaseData.value.city);
  if(result.data) {
    autocompleteZipPlaceSelection.value = result.data;
  } else {
    autocompleteZipPlaceSelection.value = [];
  }
  analyzeBaseData();
}

const applyAddress = (event) => {
  let result = event.value;
  memberBaseData.value.address = result.StreetName + " " + result.HouseNo + result.HouseNoAddition;
  memberBaseData.value.postCode = result.ZipCode;
  memberBaseData.value.city = result.TownName;
  memberBaseData.value.county = result.Canton;
  memberBaseData.value.countryCode = result.CountryCode;
  analyzeBaseData();
}

const applyPlace = (event) => {
  let result = event.value;

  memberBaseData.value.postCode = result.ZipCode;
  memberBaseData.value.city = result.TownName;
  memberBaseData.value.county = result.Canton;
  memberBaseData.value.countryCode = result.CountryCode;
  analyzeBaseData();
}

const cancel = () => {
  router.push({
          name: 'club-members',
          query: {clubId: clubStore.getCurrentClubNo}
        });
}

const resetValidationMessages = () => {
  dataValidMessage.value.emailPrivate=null,
  dataValidMessage.value.emailAlternative=null;
}

onMounted(() => {
})

;(async () => {
  await memberStore.loadCounties(i18nLocale.value);
  await memberStore.loadCountries(i18nLocale.value);
  counties.value = memberStore.getCounties;
  countries.value = memberStore.getCountries;
  analyzeBaseData();
 })()

</script>

<style scoped>

  .p-inputmask {
    width: 100%;
    margin-bottom: .25rem;
  }
</style>