export default {
  "address": {
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ort"])},
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Land"])},
    "plz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PLZ"])},
    "postbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postfach"])},
    "row1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresszeile 1"])},
    "row2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresszeile 2"])},
    "street": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Strasse"])},
    "streetnr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nr."])}
  },
  "advertisement": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzeige"])}
  },
  "appTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mySTV"])},
  "association": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Verband"]), _normalize(["Verbände"])])},
  "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurück"])},
  "breadcrumb": {
    "change2FA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sicherheit"])},
    "changeBirthday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geburtsdatum ändern"])},
    "changeEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail ändern"])},
    "changePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort ändern"])},
    "changeProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontaktdaten bearbeiten"])},
    "changeSports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sportarten bearbeiten"])},
    "club": {
      "club": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verein"])},
      "editGroups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppen / Gremien bearbeiten"])},
      "editMembers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vereinsmitglieder bearbeiten"])},
      "editPaymentMethods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlungsmethoden / Bankkonto bearbeiten"])},
      "editProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vereinsprofil bearbeiten"])},
      "editSquads": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Riegen bearbeiten"])},
      "functionsAndActivities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Funktionen und Tätigkeiten bearbeiten"])},
      "honors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ehrungen bearbeiten"])},
      "instituts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppen/Gremien"])},
      "membercards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mitgliederkarten anzeigen"])},
      "members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mitglieder"])},
      "membershipFigures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mitgliederzahlen bearbeiten"])},
      "partnersAndSponsors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sponsoren- und Partnerkontakte bearbeiten"])},
      "sync": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Synchronisation mit externer Vereinssoftware"])}
    },
    "showProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil"])}
  },
  "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbrechen"])},
  "club": {
    "changePicture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vereinslogo bearbeiten"])},
    "club": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Verein"]), _normalize(["Vereine"])])},
    "communication": {
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail-Addresse"])},
      "facebook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facebook"])},
      "homepage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Webseite"])},
      "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die hier angegebene Website und E-Mail-Adresse sowie die Konten auf den Social-Media-Plattformen werden teilweise öffentlich auf den Websites des STV angezeigt"])},
      "instagram": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instagram"])},
      "linkedIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LinkedIn"])},
      "tikTok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TikTok"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommunikation"])},
      "x": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["X"])}
    },
    "deletePicture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vereinslogo löschen"])},
    "foundingYear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gründungsjahr"])},
    "functionsAndActivities": {
      "activity": {
        "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speichern"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tätigkeiten"])},
        "titleEdit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tätigkeit editieren"])},
        "titleNew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue Tätigkeit erfassen"])},
        "titleList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Übersicht Mitglieder mit Tätigkeiten"])},
        "notFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es wurden keine Tätigkeiten gefunden."])},
        "edit": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tätigkeit editieren"])}
        },
        "delete": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tätigkeit löschen"])}
        },
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tätigkeit"])},
        "deleteConfirmButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tätigkeit entfernen"])},
        "deleteSuccessHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tätigkeit entfernt"])},
        "deleteSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Tätigkeit wurde beim entsprechenden Mitglied erfolgreich entfernt."])},
        "deleteHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tätigkeit entfernen?"])},
        "deleteQuestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Willst du die Tätigkeit wirklich entfernen?"])},
        "deleteNotFoundHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nicht gefunden"])},
        "deleteNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Tätigkeit konnte nicht gelöscht werden, weil sie nicht gefunden wurde. Eventuell wurde sie schon von einem anderen Benutzer entfernt."])},
        "editNotFoundHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nicht gefunden"])},
        "editNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Tätigkeit konnte nicht editiert werden, da sie nicht gefunden wurde. Eventuell hat sie ein anderer Benutzer entfernt."])},
        "pointsEachYear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punktzahl pro Jahr"])},
        "successCreatingNewHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erfolgreich erstellt"])},
        "successCreatingNew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Tätigkeit wurde erfolgreich erstellt."])},
        "successEditing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Tätigkeit wurde erfolgreich editiert."])},
        "successEditingHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erfolgreich editiert"])},
        "yearStart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beginn Jahr"])},
        "yearEnd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ende Jahr"])}
      },
      "add": {
        "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Funktion speichern"])},
        "selectFunction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Funktion auswählen"])},
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Funktionszuweisung wurde erfolgreich gespeichert"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue Funktion zuweisen"])}
      },
      "delete": {
        "question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Willst du diese Funktion wirklich entfernen?"])},
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Funktion wurde erfolgreich entfernt"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Funktion entfernen"])}
      },
      "edit": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Funktion bearbeiten"])}
      },
      "errors": {
        "addClubNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte eine Clubnummer angeben"])},
        "addContact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte einen Kontakt angeben"])},
        "addFunctionCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte eine Funktion angeben"])},
        "byDateInvalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ungültiges Startdatum"])},
        "moreThanOne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese Funktionsbeziehung (Person und Funktion) ist bereits vorhanden."])},
        "toDateInvalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ungültiges Enddatum"])}
      },
      "list": {
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code"])},
        "contactNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["STV-Nummer"])},
        "function": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Funktion"])},
        "notFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Funktionen und Tätigkeiten vorhanden"])}
      },
      "listTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Übersicht Mitglieder mit Funktionen"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Funktionen und Tätigkeiten"])}
    },
    "honors": {
      "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ehrung hinzufügen"])},
      "delete": {
        "hasMembers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ehrung kann nicht gelöscht werden, da noch Adressen zugewiesen sind."])},
        "question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Willst du diese Ehrung wirklich löschen?"])},
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ehrung erfolgreich gelöscht."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ehrung löschen"])}
      },
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ehrung"])},
      "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese Ehrung gibt es bereits."])},
      "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ehrung bearbeiten"])},
      "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
      "list": {
        "notFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Ehrungen vorhanden"])}
      },
      "member": {
        "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mitglied mit neuer Ehrung auszeichnen"])}
      },
      "save": {
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ehrung erfolgreich gespeichert."])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vergebene Ehrungen"])},
      "validation": {
        "clubNumberError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Club: Bitte eine gültige Club-Nummer angeben."])},
        "commentMaxLength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Maximallänge von 250 Zeichen wurde überschritten"])},
        "descriptionEmptyOrExceeded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ehrung: Eingabe erforderlich (max. 30 Zeichen)"])},
        "honorIdError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code: Bitte eine gültige Nummer angeben (max. 20 Zeichen)"])},
        "invalidDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum ungültig"])},
        "selectHonor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte eine Ehrung auswählen"])},
        "selectMember": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte ein Mitglied auswählen"])}
      }
    },
    "listMember": {
      "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Strasse"])},
      "birthday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geburtstag"])},
      "eMailAlternative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail Altern."])},
      "eMailPrivate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail"])},
      "eMailVerband": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail Verband"])},
      "eMailVerein": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail Verein"])},
      "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorname"])},
      "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprache"])},
      "lsaTypeOfSport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sportart LSA"])},
      "lsaValidYear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gültigkeit LSA"])},
      "membershipCategory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorie"])},
      "membershipCategoryAddition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zusatz Bezeichnung"])},
      "nr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["STV-Nummer"])},
      "phoneBusiness": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefon Geschäft"])},
      "phoneMobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobiltelefon"])},
      "phonePrivate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefon privat"])},
      "place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ort"])},
      "releaseMySTV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freigabe mySTV"])},
      "salutation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anrede"])},
      "salutationMR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Herr"])},
      "salutationMS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frau"])},
      "socialInsuranceNr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sozialversicherungsnr"])},
      "squads": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Riege"]), _normalize(["Riegen"])])},
      "statusMySTV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status mySTV"])},
      "surname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachname"])},
      "swissAthleticsNr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Swiss Athletics Nr"])},
      "zipCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PLZ"])}
    },
    "listSponsor": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name Unternehmen"])},
      "name2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zusatz Name Unternehmen"])}
    },
    "logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vereinslogo"])},
    "member": {
      "actions": {
        "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tätigkeit"])},
        "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beginn"])},
        "notFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Tätigkeiten vorhanden"])},
        "pointsInTotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punkte Total"])},
        "pointsPerYear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punktzahl pro Jahr"])},
        "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ende"])}
      },
      "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse"])},
      "addressAddition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresszusatz"])},
      "addressAddition2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresszusatz 2"])},
      "birthday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geburtstag"])},
      "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ort"])},
      "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Land"])},
      "county": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanton"])},
      "dateOfDeath": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todesdatum"])},
      "emailAlternative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail Alternativ"])},
      "emailPrivate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail"])},
      "emailVerein": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail Verein"])},
      "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorname"])},
      "functions": {
        "function": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Funktion"])},
        "groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppe/Gremium"])},
        "notFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Funktionen vorhanden"])}
      },
      "honors": {
        "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ehrungsdatum"])},
        "honor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ehrung"])},
        "notFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Ehrungen vorhanden"])}
      },
      "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprache"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name Unternehmen"])},
      "name2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zusatz Name Unternehmen"])},
      "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neues Mitglied"])},
      "phoneBusiness": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefon Geschäft"])},
      "phoneMobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobiltelefon"])},
      "phonePlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte eine gültige Telefonnummer eingeben"])},
      "phonePrivate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefon privat"])},
      "postCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PLZ"])},
      "profile": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Profil von ", _interpolate(_named("stvNr")), " - ", _interpolate(_named("firstName")), " ", _interpolate(_named("surname"))])},
      "release": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freigabe mySTV"])},
      "releaseInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hier kann die STV-Nummer einer erziehungsberechtigten Person mit STV-Nummer eingegeben werden. Diese Person erhält dadurch Zugriff auf den persönlichen mySTV-Account des ausgewählten Mitglieds und kann auch gewisse Personendaten des Mitglieds editieren."])},
      "remarks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bemerkungen"])},
      "salutation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anrede"])},
      "salutationMR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Herr"])},
      "salutationMS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frau"])},
      "save": {
        "buttonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eingabe speichern"])},
        "failure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Daten konnten nicht gespeichert werden."])},
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Daten wurden erfolgreich gespeichert."])}
      },
      "socialInsuranceNr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sozialversicherungsnummer"])},
      "stvNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["STV-Nummer"])},
      "subscriptions": {
        "addressRelease": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tbd_Adressfreigabe"])},
        "addressReleaseLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tbd_Meine Adresse darf an Sponsoren und Partner weitergegeben werden."])},
        "advertising": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tbd_Werbung"])},
        "advertisingLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tbd_Ja, ich möchte gerne Werbung erhalten"])},
        "alderEisenhutCatalog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tbd_Alder + Eisenhut Katalog"])},
        "alderEisenhutCatalogLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tbd_Ich möchte den Alder + Eisenhut Katalog nicht erhalten"])},
        "gymLive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tbd_Gym Live Abo"])},
        "gymLiveLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tbd_Ja, ich möchte gerne ein Gym Live Abo"])},
        "regional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tbd_Regional Abo"])},
        "regionalLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tbd_Ja, ich möchte gerne ein Regional Abo"])}
      },
      "surname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachname"])},
      "swissAthleticsNr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Swiss Athletics Nr"])},
      "titles": {
        "activities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tätigkeiten"])},
        "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse"])},
        "communication": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommunikation"])},
        "functions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktuelle Funktionen"])},
        "honors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ehrungen"])},
        "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil"])},
        "subscriptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonnemente"])}
      },
      "validation": {
        "emptyOrExceeded": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), ": Eingabe erforderlich (max. ", _interpolate(_list(1)), " Zeichen)"])},
        "emptyOrShortOrExceeded": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), ": Eingabe erforderlich (min. ", _interpolate(_list(1)), ", max. ", _interpolate(_list(2)), " Zeichen)"])},
        "invalidFormat": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), ": Ungültiges Format"])},
        "invalidNumber": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), ": Ungültige Nummer"])},
        "invalidSelection": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), ": Ungültige Auswahl"])},
        "releaseMySTVFSG": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), ": Ungültiges Mitglied"])}
      }
    },
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vereinsname"])},
    "nameInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieser Name wird unter anderem für die Anzeige auf Ranglisten und Statistiken oder im Turnverein-Finder verwendet."])},
    "notFound": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es konnten keine Informationen zum Club gefunden werden."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Clubdaten verfügbar"])}
    },
    "nr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vereinsnummer"])},
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informationen Verein"])},
    "save": {
      "data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daten speichern"])},
      "failure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Daten konnten nicht gespeichert werden."])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Daten wurden erfolgreich gespeichert."])}
    },
    "sponsor": {
      "profile": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Profil von ", _interpolate(_named("stvNr")), " - ", _interpolate(_named("name")), " ", _interpolate(_named("name2"))])},
      "save": {
        "buttonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eingabe speichern"])},
        "failure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Daten konnten nicht gespeichert werden."])},
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Daten wurden erfolgreich gespeichert."])}
      }
    },
    "squads": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Riegen"])},
    "stvName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vereinsname STV-Datenbank"])},
    "stvNameInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieser Name wird nur intern für die Datenbank des STV verwendet und nicht öffentlich angezeigt."])},
    "sync": {
      "dataProtection": {
        "linkText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datenschutzbestimmungen"])},
        "text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Ich bestätige, dass ich die ", _interpolate(_list(0)), " gelesen habe und akzeptiere."])}
      },
      "information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn euer Verein zur Administration der Vereins- und Mitgliederdaten eine Vereinssoftware von Localclubs, Clubdesk oder Fairgate nutzt, könnt ihr hier die Vereinsschnittstelle des STV aktivieren, damit gewisse Daten eures Vereines und eurer Mitglieder automatisch synchronisiert werden."])},
      "invalidProvider": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte eine Vereinssoftware auswählen"])},
      "invalidToken": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte einen gültigen Zugriffsschlüssel angeben"])},
      "noData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["              Keine Informationen zur Vereinsschnittstelle vorhanden "])},
      "noProvider": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["keine"])},
      "provider": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anbieter Vereinssoftware"])},
      "token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zugriffsschlüssel Webdienst"])}
    },
    "uploadNewPicture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neues Vereinslogo hochladen"])},
    "validation": {
      "emptyOrExceeded": {
        "displayName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vereinsname: Eingabe erforderlich (max. 50 Zeichen)"])}
      },
      "emptySelection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte eine Auswahl treffen"])},
      "invalidIBAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte eine gültige IBAN eingeben"])},
      "invalidLength": {
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail: Die Maximallänge von 80 Zeichen wurde überschritten"])},
        "facebook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facebook: Die Maximallänge von 80 Zeichen wurde überschritten"])},
        "homepage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Homepage: Die Maximallänge von 80 Zeichen wurde überschritten"])},
        "instagram": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instagram: Die Maximallänge von 80 Zeichen wurde überschritten"])},
        "linkedIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LinkedIn: Die Maximallänge von 80 Zeichen wurde überschritten"])},
        "tikTok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TikTok: Die Maximallänge von 80 Zeichen wurde überschritten"])},
        "x": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["X: Die Maximallänge von 80 Zeichen wurde überschritten"])}
      },
      "invalidMailFormat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte eine gültige E-Mail Adresse eingeben."])},
      "invalidYear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte eine gültige Jahreszahl eingeben"])}
    }
  },
  "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bemerkung"])},
  "contact": {
    "bankAccount": {
      "countries": {
        "ch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schweiz"])},
        "fl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liechtenstein"])}
      },
      "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bankkoordinaten"])},
      "infotext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte hinterlegt die gewünschte Zahlungsart sowie die Bankkoordinaten eures Vereines, damit der STV Auszahlungen oder Rückerstattungen an euren Verein vornehmen kann."])},
      "labels": {
        "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ort"])},
        "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Land"])},
        "iban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])},
        "nameOfInsitute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name der Bank"])},
        "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eingabe speichern"])},
        "zipCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postleitzahl"])}
      },
      "methods": {
        "bankPaymentDomestic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bankzahlung Inland"])},
        "bankPaymentForeign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bankzahlung Ausland"])},
        "postPaymentDomestic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postzahlung Inland"])},
        "postPaymentForeign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postzahlung Ausland"])},
        "swiftForeign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SWIFT-Zahlung Ausland"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlungsart"])}
      },
      "save": {
        "failure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Daten konnten nicht gespeichert werden."])},
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Daten wurden erfolgreich gespeichert."])}
      },
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Bankkoordinaten ", _interpolate(_named("name"))])},
      "validation": {
        "cityExceeded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ort: Die Maximallänge von 30 Zeichen wurde überschritten"])},
        "invalidCountry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ungültige Länderauswahl. Zulässige Werte sind 'CH' sowie 'FL'"])},
        "invalidIBAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN: Bitte eine gültige IBAN eingeben."])},
        "invalidIBANCountry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN: Ungültiger Ländercode. Es können nur IBAN der Länder Schweiz und Liechtenstein verwendet werden."])},
        "invalidIBANCountryCombination": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN: Unzulässiger Ländercode. Der Ländercode muss mit dem ausgewählten Land übereinstimmen"])},
        "invalidPaymentSelection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlungsart: Bitte eine gültige Zahlungsart auswählen"])},
        "nameEmptyOrExeeded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name: Eingabe erforderlich (max. 50 Zeichen)"])},
        "postCodeExceeded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postleitzahl: Die Maximallänge von 20 Zeichen wurde überschritten"])}
      }
    }
  },
  "create": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("object")), " erstellen"])},
  "createNewMember": {
    "additional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zusätzlich"])},
    "additional2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zusätzlich2"])},
    "birthday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geburtsdatum"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ort"])},
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Land"])},
    "createNewMember": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neues Mitglied erfassen"])},
    "doDuplicateCheck": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prüfen"])},
    "duplicateMemberInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Angaben stimmen mit einer oder mehreren Personen überein, welche bereits in unserer Datenbank erfasst ist. Bitte prüfe, ob es sich um die gleiche Person handelt. Wenn ja, setzte bitte das Häckchen bei dieser Person und klicke danach auf den Button “Personen zusammenführen”. Wenn es sich um eine neue Person handelt, klicke bitte auf den Button “Neue Person als Mitglied erfassen”."])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail"])},
    "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorname"])},
    "gymClub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TV"])},
    "ignoreDuplicates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue Person als Mitglied erfassen"])},
    "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachname"])},
    "mergeDuplicates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personen zusammenführen"])},
    "salutation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anrede"])},
    "street": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Strasse"])},
    "zipcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PLZ"])}
  },
  "cropper": {
    "zoomIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vergrössern"])},
    "zoomOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verkleinern"])}
  },
  "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum"])},
  "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Löschen"])},
  "edit": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("object")), " bearbeiten"])},
  "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-"])},
  "errorMessages": {
    "access_denied_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kein Zugriff"])},
    "access_denied_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese Funktion steht dir leider nicht zur Verfügung."])},
    "generic_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unerwarteter Fehler"])},
    "invalid_credentials_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anmeldefehler"])},
    "invalid_credentials_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anmeldung fehlgeschlagen. Bitte überprüfen Sie Ihre Zugangsdaten."])},
    "invalid_session_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sitzung abgelaufen"])},
    "invalid_session_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihre Sitzung ist abgelaufen. Bitte melden Sie sich erneut an."])},
    "network_error_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Applikation steht aktuell nicht zur Verfügung. Versuchen Sie es später bitte nochmal!"])}
  },
  "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exportieren"])},
  "faq": {
    "infoText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fragen zu mySTV? Schau doch auf unserer Infoseite und in unserem F.A.Q. rein."])}
  },
  "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter"])},
  "footer": {
    "dataProtection": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datenschutz"])},
      "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.stv-fsg.ch/de/datenschutz.html"])}
    },
    "goldPartner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gold-Partner"])},
    "impressum": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impressum"])},
      "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.stv-fsg.ch/de/impressum.html"])}
    },
    "platinPartner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Platin-Partner"])},
    "termsConditions": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AGB"])},
      "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.stv-fsg.ch/de/agb.html"])}
    }
  },
  "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["von"])},
  "functions": {
    "aktivmitglied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivmitglied"])},
    "ehrenmitglied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ehrenmitglied"])},
    "kassier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kassier"])},
    "kassierin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kassierin"])},
    "mitgliedGeraeteturnen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mitglied Geräteturnen"])}
  },
  "here": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hier"])},
  "institutes": {
    "add": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppe/Gremium hinzufügen"])}
    },
    "addresses": {
      "list": {
        "noData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine zugewiesenen Mitglieder gefunden"])}
      }
    },
    "delete": {
      "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppe/Gremium löschen"])},
      "failure": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Fehler beim Löschen der Gruppe/des Gremiums \"", _interpolate(_named("groupName")), "\""])},
      "hasMembers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Datensatz kann nicht gelöscht werden, da noch Organadressen vorhanden sind."])},
      "question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Willst du diese Gruppe / dieses Gremium wirklich löschen?"])},
      "success": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Gruppe/Gremium \"", _interpolate(_named("groupName")), "\" erfolgreich gelöscht."])},
      "text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Willst du die Gruppe/das Gremium \"", _interpolate(_named("groupName")), "\" (Nr. ", _interpolate(_named("code")), ") wirklich löschen?"])},
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Gruppe/Gremium \"", _interpolate(_named("groupName")), "\" löschen"])}
    },
    "edit": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name Gruppe/Gremium"])},
      "save": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Gruppe/Gremium ", _interpolate(_named("groupName")), " speichern"])},
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Gruppe/Gremium ", _interpolate(_named("groupName")), " bearbeiten"])}
    },
    "filter": {
      "clubNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suche nach Club-Nummer"])}
    },
    "functions": {
      "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Funktionsbeziehung hinzufügen"])},
      "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Funktion erstellen"])},
      "delete": {
        "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Funktion löschen"])},
        "failure": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Fehler beim Löschen der Funktion ", _interpolate(_named("functionName"))])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Funktion löschen"])},
        "question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Willst du diese Funktion wirklich löschen?"])},
        "success": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Die Funktion ", _interpolate(_named("functionName")), " wurde erfolgreich gelöscht."])}
      },
      "edit": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Funktion ", _interpolate(_named("functionName")), " bearbeiten"])},
      "manage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Funktionen verwalten"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Funktionsname"])},
      "save": {
        "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler beim Speichern der Funktion"])},
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Funktion wurde erfolgreich gespeichert"])}
      },
      "selectFunction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Funktion auswählen"])},
      "selectMember": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mitglied auswählen"])},
      "step": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stufe"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Funktionen"])},
      "validation": {
        "noDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte einen Funktionsnamen angeben"])},
        "noStep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte eine Stufe angeben"])}
      }
    },
    "list": {
      "clubNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Club-Nummer"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppe/Gremium"])},
      "instituteNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nr."])},
      "noInstitutesFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Gruppen/Gremien gefunden"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktuelle Gruppen/Gremien"])}
    },
    "members": {
      "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neues Mitglied hinzufügen"])},
      "delete": {
        "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mitglied aus der Gruppe entfernt"])},
        "failure": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Fehler beim Entfernen des Mitglieds ", _interpolate(_named("member")), " aus der Gruppe"])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mitglied aus der Gruppe entfernen"])},
        "question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Willst du dieses Mitglied wirklich entfernen?"])},
        "success": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Mitglied ", _interpolate(_named("member")), " wurde aus der Gruppe entfernt"])}
      },
      "edit": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Funktion in der Gruppe"])}
      }
    },
    "modal": {
      "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppen/Gremien"])}
    },
    "save": {
      "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese Nummer wird bereits verwendet. Bitte wähle eine neue Nummer."])},
      "failure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler beim Speichern"])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppe/Gremium gespeichert"])}
    },
    "validation": {
      "nameEmptyOrExceeded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name: Eingabe erforderlich (max. 50 Zeichen)"])},
      "noClubSpecified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Club: Bitte eine gültige Club-Nummer angeben"])},
      "noContactSpecified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte ein Mitglied angeben"])},
      "noFunctionSpecified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte eine Funktion angeben"])},
      "noInstituteSpecified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte ein Gremium/eine Gruppe angeben"])},
      "noStartDateSpecified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte ein Startdatum angeben"])},
      "numberEmptyOrExceeded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nummer: Eingabe erforderlich (max. 30 Zeichen)"])},
      "stepExceeded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stufe: Eingabe zu lang (max. 10 Zeichen)"])}
    }
  },
  "languages": {
    "de": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deutsch"])},
    "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Englisch"])},
    "fr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Französisch"])},
    "it": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Italienisch"])}
  },
  "links": {
    "faq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.stv-fsg.ch/de/mitglied-verein/mystv-digitale-mitgliederkarte.html"])},
    "partner": {
      "coop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.coop.ch/de/unternehmen/ueber-uns.html"])},
      "jako": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.stv-fsg.ch/de/ueber-den-stv/sponsoren-partner/partner-1.html#c20941"])},
      "localCities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.stv-fsg.ch/de/ueber-den-stv/sponsoren-partner/partner-1.html#c20939"])},
      "mobilezone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.stv-fsg.ch/de/ueber-den-stv/sponsoren-partner/partner-1.html#c20942"])},
      "ochsner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.ochsnersport.ch/de/shop/"])},
      "swica": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.swica.ch/de/partner/sportfoerderung/stv/swica-vorteile"])}
    },
    "privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.stv-fsg.ch/de/datenschutz.html"])}
  },
  "login": {
    "error": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("detail"))])},
    "intro": {
      "caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zentralpräsident"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Herzlich Willkommen bei mySTV, der digitalen Mitgliederplattform des Schweizer Turnsports. Ich danke dir herzlich für deine Mitgliedschaft im Turnverein und für dein Engagement für das Schweizer Turnen!"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Danke für dein Engagement."])}
    },
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abmelden"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort"])},
    "passwordError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort: Eingabe erforderlich"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bei mySTV anmelden"])},
    "totp": {
      "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2-Faktor-Authentifizierung: Eingabe erforderlich"])},
      "info": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Mit diesem zusätzlichen Schritt bestätigst du, dass du dich mit ", _interpolate(_list(0)), " bei mySTV anmelden willst.\nGib bitte den 6-stelligen Bestätigungs-Code aus der Authenticator-App auf deinem Mobiltelefon ein."])},
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login mit 2-Faktor-Authentifizierung"])}
    },
    "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail oder Mitglied-Nummer"])},
    "usernameError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzername: Eingabe erforderlich"])}
  },
  "lspa": {
    "startPage": {
      "smallTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leistungssportausweise bearbeiten"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leistungssportausweise bearbeiten"])}
    },
    "status": {
      "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
      "ACTIVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiv"])},
      "EXPIRED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abgelaufen"])},
      "NO_ACTIVE_MEMBERSHIP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehlende Aktiv-Mitgliedschaft"])}
    },
    "validYear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gültigkeit Leistungssportausweis"])},
    "sportId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sportart"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbrechen"])},
    "confirmOrderRenewButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leistungssportausweise bestellen"])},
    "clubName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vereinsname"])},
    "createNew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leistungssportausweis bei neuen Personen hinzufügen"])},
    "confirmCreateNew": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Für diese Person den Leistungssportausweis für das Jahr ", _interpolate(_named("currentYear")), " kostenpflichtig bestellen"])},
    "confirmOrderRenew": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Möchtest du bei den ausgewählten Mitgliedern den Leistungssportausweis für das Jahr ", _interpolate(_named("currentYear")), " kostenpflichtig bestellen?"])},
    "confirmOrderRenewHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestätigen"])},
    "renewOld": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leistungssportausweis erneuern"])},
    "membershipNr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mitgliedernummer"])},
    "searchForMember": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mitglied suchen und hinzufügen"])},
    "successCreatingNew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leistungssportausweis wurde erfolgreich bestellt."])},
    "successCreatingNewHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erfolgreich bestellt"])},
    "successRenew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leistungssportausweise wurden erfolgreich erneuert."])},
    "successRenewHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erfolgreich erneuert"])},
    "memberNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese Mitgliedernummer gibt es nicht oder die dazugehörigen Daten stimmen nicht überein."])},
    "memberNotFoundHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mitglied nicht gefunden"])},
    "memberInactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese Person verfügt über keine gültige Aktivmitgliedschaft."])},
    "memberInactiveHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Aktivmitgliedschaft"])},
    "limitedPeriodInfotext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leistungssportausweise für die olympischen Sportarten Kunstturnen, Rhythmische Gymnastik und Trampolin können jeweils vom 1. Januar bis zum 30. April des jeweiligen Kalenderjahres bestellt werden."])},
    "limitedPeriodHasPassedInfoText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Frist für das aktuelle Jahr ist bereits abgelaufen. Bei allfälligen Fragen wende Dich bitte an spitzensport", "@", "stv-fsg.ch"])}
  },
  "membership": {
    "eintritt": {
      "panel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eintritt STV-Anlässe"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schweizer Meisterschaften des STV"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kostenloser Eintritt"])}
    },
    "leistungsSportLizenz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leistungssportausweis"])},
    "olympischeLizenz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lizenz Olympische Mission"])},
    "richterbrevet": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Richterbrevet"]), _normalize(["Richterbrevets"])])},
    "sportsInsurance": {
      "infoLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.stv-fsg.ch/de/sportversicherungskasse-svk.html"])},
      "infoLinkText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Infos Sportversicherungskasse"])},
      "serviceLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.stv-fsg.ch/de/sportversicherungskasse/leistungen.html"])},
      "serviceLinkText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leistungen"])},
      "text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Die aktiv turnenden STV-Mitglieder sind gemäss Reglement bei der Sportversicherungskasse des STV (SVK) gegen Haftpflicht-, Brillenschäden und Unfälle (in Ergänzung zu Drittversicherungen) versichert. ", _interpolate(_named("link"))])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sportversicherungskasse"])}
    },
    "voluntaryWorks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ehrungen"])}
  },
  "menu": {
    "manageClub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vereinsverwaltung"])},
    "manageClubAndMemberData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vereins- und Mitgliederdaten bearbeiten"])},
    "manageLSPA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leistungssportausweise bearbeiten"])},
    "memberCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meine Karte"])},
    "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Übersicht"])},
    "ownProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mein Profil"])},
    "personalData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personalien"])},
    "releasedMembers": {
      "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurück zu deinem Konto"])},
      "memberCard": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Mitgliederkarte von ", _interpolate(_named("releaseMember"))])},
      "profile": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Profil von ", _interpolate(_named("releaseMember"))])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Familienmitglieder"])}
    },
    "singleMember": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einzelmitglied"])},
    "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menü"])}
  },
  "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weiter"])},
  "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nein"])},
  "noData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es wurden keine Datensätze gefunden"])},
  "notFound": {
    "checkLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prüfe bitte, ob der Link korrekt ist."])},
    "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zur Startseite"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der angegebene Pfad konnte nicht gefunden werden."])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nicht gefunden"])}
  },
  "offers": {
    "clubs": {
      "intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sponsoren zu finden ist nicht immer eine einfache Aufgabe – sei dies für den eigenen Verein oder für einen Event. Der STV hat verschiedene attraktive Partnerschaften, von welchen auch du als Verein oder OK profitieren kannst. Schau rein – es lohnt sich auf jeden Fall."])},
      "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angebote für Vereine"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profitiert von den Partnern des STV"])}
    },
    "members": {
      "intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Als Mitglied des STV unterstützt du direkt den Schweizer Turnsport und ermöglichst die vielseitigen Angebote des STV und seinen Turnvereinen. Als kleines Dankeschön profitieren alle aktiv Turnenden von besonderen Angeboten und Rabatten bei diversen Partnern des STV."])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angebote für Mitglieder"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vielen Dank für deine Treue und Unterstützung!"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mitgliederangebote"])}
  },
  "pagination": {
    "of": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["von"])},
    "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-"])}
  },
  "personalData": {
    "person": {
      "birthday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geburtsdatum"])},
      "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorname"])},
      "gender": {
        "d": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andere"])},
        "f": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weiblich"])},
        "gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geschlecht"])},
        "m": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Männlich"])}
      },
      "nameAddition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Namenszusatz"])},
      "person": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Person"])},
      "squads": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Riegen"])},
      "stvNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["STV-Nummer"])},
      "surname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])}
    }
  },
  "squad": {
    "createNewSquad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue Riege erstellen"])},
    "deleteSquad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Riege löschen"])},
    "editSquad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Riege bearbeiten"])}
  },
  "place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ort"])},
  "print": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("object")), " Drucken"])},
  "profile": {
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse"])},
    "addressInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Damit wir die Verbandszeitschrift GYMLive sowie deine Zertifikate und Brevets zustellen können."])},
    "birthday": {
      "changeBirthday": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize([_linked("breadcrumb.changeBirthday", undefined, _type)])},
      "changeSuccessful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dein Geburtsdatum wurde erfolgreich angepasst."])},
      "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es ist möglich, dass dein Verein ein falsches Geburtsdatum erfasst hat. Hier kannst du dies anpassen."])},
      "saveNewBirthday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geburtsdatum speichern"])},
      "title": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize([_linked("personalData.person.birthday", undefined, _type)])}
    },
    "changePicture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profilbild bearbeiten"])},
    "changeSports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sportarten bearbeiten"])},
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meine Kontaktdaten"])},
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Land"])},
    "delete": {
      "alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Willst du dein Profilbild wirklich löschen?"])}
    },
    "deletePicture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profilbild löschen"])},
    "editProfile": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize([_linked("breadcrumb.changeProfile", undefined, _type)])},
    "email": {
      "changeEmail": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize([_linked("breadcrumb.changeEmail", undefined, _type)])},
      "changeFailure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es ist ein Fehler aufgetreten. Deine E-Mail-Adresse konnte nicht geändert werden."])},
      "changePending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dein Link wird geprüft …"])},
      "changeSubmitted": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["<p>E-Mail geändert: Überprüfe dein Postfach für die Adresse <strong>", _interpolate(_named("email")), "</strong> und bestätige die Adresse.</p><p>Bis dann wird die Änderung noch nicht übernommen.</p>"])},
      "changeSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deine E-Mail wurde geändert."])},
      "confirmEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue E-Mail-Adresse bestätigen"])},
      "currentEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktuelle E-Mail"])},
      "emailAlreadyInUse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese E-Mail Adresse wird bereits verwendet. Bitte gib eine neue, gültige E-Mail Adresse ein."])},
      "enterPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort eingeben"])},
      "invalidConfirmationPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das von dir angegebene Passwort ist ungültig. Bitte überprüfe deine Eingabe."])},
      "invalidPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gib bitte dein Passwort an, um die E-Mail-Adresse ändern zu können."])},
      "newEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue E-Mail"])},
      "passwordInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte gib dein Passwort ein, um die E-Mail-Adresse ändern zu können."])},
      "submitEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue E-Mail-Adresse speichern"])},
      "submitFailure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beim Ändern der E-Mail-Adresse ist ein Fehler aufgetreten. Bitte versuche es erneut."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail"])},
      "tokenInvalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der angegebene Link ist nicht mehr gültig. Bitte prüfe die Adresszeile des Browsers oder setze deine E-Mail-Adresse erneut zurück."])},
      "wrongEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte eine gültige E-Mail-Adresse eingeben."])}
    },
    "emailInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Für dein Login und für Updates des STV sowie deines Turnvereins. Ausserdem kannst du dich damit für Wettkämpfe und Kurse anmelden."])},
    "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorname"])},
    "fullName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vor- und Nachname"])},
    "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachname"])},
    "mailBox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postfach"])},
    "memberNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mitgliedernummer oder E-Mail"])},
    "password": {
      "currentPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktuelles Passwort"])},
      "edit": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize([_linked("breadcrumb.changePassword", undefined, _type)])},
      "failure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dein Passwort konnte nicht geändert werden. Überprüfe deine Eingaben und versuche es bitte nochmals."])},
      "length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mindestens 7 Buchstaben lang"])},
      "match": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort stimmt überein"])},
      "matchOld": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das neue Passwort darf nicht dem bisherigen Passwort entsprechen"])},
      "newPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neues Passwort"])},
      "oldPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort ausgefüllt"])},
      "passwordConfirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neues Passwort (wiederholen)"])},
      "savePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neues Passwort speichern"])},
      "specialChars": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sonderzeichen vorhanden"])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dein Passwort wurde erfolgreich geändert."])},
      "upperLowerCase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gross- und Kleinbuchstaben vorhanden"])}
    },
    "phone": {
      "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Damit deine Trainer*innen dich erreichen können."])},
      "noPhoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du hast noch keine Telefonnummer angegeben."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefon"])}
    },
    "phoneInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Damit deine Trainer*innen dich erreichen können."])},
    "picture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profilbild"])},
    "pictureSizeExceeded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die ausgewählte Datei übersteigt die zulässige Grösse von 16MB"])},
    "place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ort"])},
    "save": {
      "failure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deine Daten konnten nicht gespeichert werden."])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deine Daten wurden erfolgreich gespeichert."])}
    },
    "saveChanges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Änderungen speichern"])},
    "saveProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontaktdaten speichern"])},
    "security": {
      "authenticatorInfo": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scanne den QR-Code mit einer Authenticator App (z.B. Google Authenticator, FreeOTP). Übertrage anschliessend den Code aus der App in das untenstehende Eingabefeld und wähle eine Bezeichnung, damit du das Gerät eindeutig identifizieren kannst. Um die Registration deines Geräts abzuschliessen, musst du anschliessend den Button am Ende anklicken."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorgehen"])}
      },
      "device": {
        "delete": {
          "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbrechen"])},
          "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Löschen"])},
          "failure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dein Gerät konnte nicht entfernt werden."])},
          "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dein Gerät wurde erfolgreich entfernt."])},
          "text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Möchtest du das ausgewählte Gerät \"", _interpolate(_list(0)), "\" wirklich entfernen?"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gerät entfernen"])}
        }
      },
      "deviceName": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezeichnung deines Gerätes"])},
        "length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezeichnung zwischen 3 und 100 Zeichen"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["z.B. Nokia 3210"])}
      },
      "devices": {
        "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hinzufügen"])},
        "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Löschen"])},
        "noDevicesFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du hast noch kein Gerät registriert."])},
        "register": {
          "failure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dein Gerät konnte nicht registriert werden. Bitte versuche es nochmals"])},
          "failureDeviceName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Bezeichnung deines Gerätes enhält nicht die erforderliche Anzahl von 3-100 Zeichen"])},
          "failureInitalCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dein initialer Code enthält nicht die erforderlichen 6 Zahlen"])},
          "failureOneDeviceOnly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du hast bereits ein Gerät registriert. Leider kann nur ein Gerät registriert werden."])},
          "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dein Gerät wurde erfolgreich registriert."])}
        }
      },
      "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zweistufige Authentifizierung"])},
      "explanation": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durch das Hinzufügen deines Gerätes wird die zweistufige Authentifizierung automatisch aktiviert. Ab diesem Zeitpunkt wirst du beim Anmelden jeweils nach einem sechstelligen Sicherheitscode gefragt, welchen du aus der Authenticator-Applikation auslesen kannst. Du deaktivierst diese zusätzliche Sicherheitsstufe, indem du dein Gerät aus der untenstehenden Liste wieder entfernst."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivieren/Deaktivieren der zweistufigen Authentifizierung"])}
      },
      "initialCode": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Initialer Code aus Authenticator Applikation"])},
        "length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6 Zahlen"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["z.B. 140792"])}
      },
      "qrCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dein QR-Code zum Einrichten eines neuen Gerätes"])},
      "saveDevice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gerät registrieren"])},
      "secret": {
        "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schlüssel kopieren"])},
        "copyToolTip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klicke hier um den Einrichtungsschlüssel in die Zwischenablage zu kopieren."])},
        "keyHint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hinweis: Es handelt sich um einen zeitbasierten Schlüssel."])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einrichtungsschlüssel"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solltest du den QR-Code nicht scannen können, kannst du die Einrichtung auch manuell vornehmen. Dazu kopierst du den Einrichtungsschlüssel und fügst diesen in deiner Authenticator-Applikation ein."])}
      }
    },
    "showProfile": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize([_linked("breadcrumb.showProfile", undefined, _type)])},
    "street": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Strasse"])},
    "uploadNewPicture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neues Profilbild hochladen"])},
    "uploadPicture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profilbild hochladen"])},
    "validation": {
      "invalidCountry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte ein gültiges Land angeben"])},
      "invalidFirstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte einen Vornamen angeben"])},
      "invalidLastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte einen Nachnamen angeben"])},
      "invalidMailBox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte ein gültiges Postfach angeben"])},
      "invalidMemberNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte eine gültige STV-Mitgliedernummer oder E-Mail eingeben"])},
      "invalidName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte einen vollständigen Namen angeben"])},
      "invalidPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte eine gültige Telefonnummer angeben"])},
      "invalidPlace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte einen Gültigen Ort angeben"])},
      "invalidStreet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte eine gültige Strasse angeben"])},
      "invalidZip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte eine gültige PLZ angeben"])}
    },
    "zip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PLZ"])}
  },
  "remarks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bemerkungen"])},
  "save": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Speichern"]), _normalize([_interpolate(_named("object")), " speichern"])])},
  "section": {
    "clubMembers": {
      "filter": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Suche nach ", _interpolate(_named("filter"))])},
      "members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mitglieder"])},
      "noMembersFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es wurden keine Aktivmitglieder gefunden"])},
      "pagination": {
        "info": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("from")), " – ", _interpolate(_named("to")), " von ", _interpolate(_named("total"))])},
        "of": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["von"])},
        "page": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("page")), " von ", _interpolate(_named("totalPages"))])},
        "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-"])}
      },
      "printSingleCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einzelkarten drucken"])},
      "selected": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Keine Karte ausgewählt"]), _normalize(["Eine Karte ausgewählt"]), _normalize([_interpolate(_named("count")), " Karten ausgewählt"])])},
      "status": {
        "CONDITION_FAILED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedingungen nicht erfüllt"])},
        "EMAIL_DUPLICATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doppelte E-Mail-Adresse"])},
        "EMAIL_INVALID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail ungültig"])},
        "EMAIL_MISSING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine E-Mail"])},
        "INITIATED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
        "OFFBOARD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In Vorbereitung"])},
        "ONBOARD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiviert"])},
        "OPT_OUT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Möchte kein mySTV-Konto"])},
        "PENDING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pendent"])},
        "QUARANTINE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Technischer Fehler"])},
        "REMINDED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pendent"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status mySTV"])}
      }
    },
    "education": {
      "brevets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meine Brevets und Weiterbildungen"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beschreibung"])},
      "education": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausbildung"])},
      "endDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enddatum"])},
      "level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stufe"])},
      "noBrevetEntries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Noch keine Brevets und Weiterbildungen vorhanden"])},
      "noEntries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Noch keine Kurse und Anmeldungen vorhanden"])},
      "sport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sportart"])},
      "startDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Startdatum"])},
      "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meine Ausbildungen"])},
      "validity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gültigkeit"])}
    },
    "function": {
      "function": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Funktion"])},
      "noEntries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Noch keine Funktionen vorhanden"])},
      "organisation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organisation"])},
      "stvMember": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["STV-Mitarbeiter/in"]), _normalize(["STV-Mitarbeiterin"]), _normalize(["STV-Mitarbeiter"])])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meine Funktionen"])}
    },
    "member": {
      "honor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ehrung"])},
      "infobox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>Bei falschen Daten und Inhalten zu deiner Vereinsmitgliedschaft wende dich bitte an den STV-Admin deines Vereins. Bei falschen Daten auf Stufe Verband melde dich bitte an deinen Kantonalverband oder an den STV.</p><p>Bei Änderungen von Daten und Inhalten, welche in mySTV oder in STV-Admin vorgenommen werden, dauert es bis zu 24 Stunden, bis sie in der jeweils anderen Plattform sichtbar sind.</p>"])},
      "memberNr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mitglied-Nr."])},
      "titleClubMember": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize([_linked("section.member.titleMembercard", undefined, _type)])},
      "titleFunctionMember": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meine Funktionärskarte"])},
      "titleMembercard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mitgliederkarte"])},
      "titleSingleMember": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meine Mitgliederkarte"])}
    },
    "membership": {
      "association": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize([_linked("association", undefined, _type)])},
      "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mitglieder-Kategorie"])},
      "entryDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eintrittsdatum"])},
      "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Kurshistory von J+S und esa kann auf den Websites von Jugend+Sport und esa abgerufen werden."])},
      "memberNr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mitglied-Nr."])},
      "memberOf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mitglied von"])},
      "membershipType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Art der Mitgliedschaft"])},
      "mutation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eintritt/Mutationen"])},
      "noClub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine gültige Aktivmitgliedschaft in einem Turnverein"])},
      "noEntries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Noch Keine Mitgliedschaften vorhanden"])},
      "squad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Riege"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meine Mitgliedschaften"])}
    },
    "section": {
      "clubMembers": {
        "printCollection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sammelbogen drucken"])}
      }
    },
    "start": {
      "adminLightMessage": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Du bist Admin beim Verein"]), _normalize(["Du bist Admin bei den Vereinen"])])},
      "adminMessage": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Du bist Admin beim Verein"]), _normalize(["Du bist Admin bei den Vereinen"])])},
      "editClubMembers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vereins- und Mitgliederdaten bearbeiten"])},
      "emulatingMember": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Du bist aktuell auf dem Konto von ", _interpolate(_named("releaseMember"))])},
      "familyMembers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du kannst folgende Familienmitglieder verwalten"])},
      "greeting": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Hoi ", _interpolate(_named("user")), "."])},
      "greetingMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schön, bist du da."])},
      "headerImage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Headerbild"])},
      "showClubMembers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deine Vereinsmitglieder anzeigen"])},
      "showMemberCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deine Mitgliederkarte anzeigen"])},
      "showReleaseMemberCard": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Mitgliederkarte von ", _interpolate(_named("releaseMember")), " anzeigen"])}
    }
  },
  "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auswählen"])},
  "sport": {
    "aerobic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aerobic"])},
    "akrobatikturnen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akrobatikturnen"])},
    "fachteste": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fachteste"])},
    "faustball": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faustball"])},
    "fit-fun": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fit+Fun"])},
    "fitness_gesundheitssport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fitness/Gesundheitssport"])},
    "geraeteturnen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geräteturnen"])},
    "gymnastik": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gymnastik"])},
    "handball": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Handball"])},
    "indiaca": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indiaca"])},
    "kinderturnen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kinderturnen"])},
    "korbball": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Korbball"])},
    "kunstturnen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kunstturnen"])},
    "leichtathletik": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leichtathletik"])},
    "nationalturnen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nationalturnen"])},
    "netzball": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Netzball"])},
    "parkour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parkour"])},
    "rhoenrad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rhönrad"])},
    "rhythmischeGymnastik": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rhythmische Gymnastik"])},
    "ropeskipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rope Skipping"])},
    "schnurball": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schnurball"])},
    "sportakrobatik": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sportakrobatik"])},
    "title": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Meine Sportart"]), _normalize(["Meine Sportarten"])])},
    "trampolin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trampolin"])},
    "turnen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Turnen"])},
    "unihockey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unihockey"])},
    "volley": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volley"])}
  },
  "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uhrzeit"])},
  "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bis"])},
  "user": {
    "forgotPassword": {
      "backToLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurück zum Login"])},
      "failure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bei der Anforderung ist ein Fehler aufgetreten. Prüfe deine Angaben und versuche es später erneut."])},
      "forgot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort vergessen"])},
      "forgot_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gib bitte deine MySTV-Mitgliedernummer oder die E-Mail-Adresse, die deinem Konto zugewiesen ist, ein. Wir werden dir einen Link zum Erstellen eines neuen Passworts senden."])},
      "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link anfordern"])},
      "success": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Die Link-Anforderung zum Zurücksetzen des Passworts wurde erfolgreich versendet. Wenn eine E-Mail-Adresse für ", _interpolate(_named("memberNumber")), " existiert, wird der Link an diese Adresse gesendet. Prüfe auch deinen Spam-Ordner."])},
      "toLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zum Login"])}
    },
    "onboarding": {
      "createAccount": {
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Falls deine Angaben einem Mitgliederkonto zugewiesen werden konnten, hast du von uns eine Nachricht zum Aktivieren deines Kontos erhalten. Prüfe bitte auch deinen Spam-Ordner.\nFalls du keine Nachricht erhalten hast, melde dich bei deinem Verein"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gib bitte deine STV-Mitgliedernummer oder die E-Mail-Adresse ein, welche dein Turnverein in der STV-Mitgliederdatenbank hinterlegt hat. Wenn deine Angaben die Bedingungen für den Zugang zu mySTV erfüllen, werden wir dir einen Link zum Erstellen eines Passworts senden."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dein mySTV-Konto aktivieren"])}
      },
      "enterPassword": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize([_linked("profile.password.newPassword", undefined, _type)])},
      "intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Definiere hier dein Passwort für deinen mySTV-Zugang."])},
      "newUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neu bei mySTV?"])},
      "passwordConfirm": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize([_linked("profile.password.passwordConfirm", undefined, _type)])},
      "privacyCheck": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Ich habe die ", _interpolate(_list(0)), " gelesen und verstanden."])},
      "privacyLinkText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datenschutzerklärung"])},
      "savePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konto aktivieren"])},
      "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link anfordern"])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dein Passwort wurde erfolgreich erstellt."])},
      "termsAndConditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nutzungsbedingungen"])},
      "termsAndConditionsCheck": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich habe die Nutzungsbedingungen gelesen und akzeptiert."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort erstellen"])},
      "tokenInvalid": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Der angegebene Link ist nicht mehr gültig. Du kannst ", _interpolate(_list(0)), " einen neuen Aktivierungslink anfordern"])}
    },
    "resetPassword": {
      "newPassword": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize([_linked("profile.password.newPassword", undefined, _type)])},
      "passwordConfirm": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize([_linked("profile.password.passwordConfirm", undefined, _type)])},
      "reset_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du kannst nun ein neues Passwort definieren."])},
      "savePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neues Passwort speichern"])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dein Passwort wurde erfolgreich zurückgesetzt."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort zurücksetzen"])},
      "tokenInvalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der angegebene Link ist nicht mehr gültig. Bitte prüfe die Adresszeile des Browsers oder fordere den Link zum Zurücksetzen des Passworts erneut an."])}
    }
  },
  "validation": {
    "emailAlreadyInUse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese E-Mail Adresse wird bereits verwendet. Bitte gib eine neue, gültige E-Mail Adresse ein."])},
    "emailNotUnique": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die E-Mail Adresse kann nur einmal verwendet werden."])}
  },
  "wizard": {
    "button": {
      "postpone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überspringen"])},
      "reject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ablehnen"])},
      "saveSports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sportarten speichern"])},
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Übernehmen"])}
    }
  },
  "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja"])}
}