import { defineStore } from "pinia";
import logger from "@/logger/index.js";

export const useMessagesStore = defineStore("messages", {
  state: () => {
    return {
      errors: [],
      warnings: [],
      infos: [],
    };
  },

  
  actions: {
    resetMessages(category) {
      this.resetInfos(category);
      this.resetWarnings(category);
      this.resetErrors(category);
    },
    resetInfos(category) {
      if(category !== null && category !== undefined) {
        this.infos = this.infos.filter((msg) => msg.category !== category);
      } else {
        this.infos = [];
      }
    },
    resetWarnings(category) {
      if(category !== null && category !== undefined) {
        this.warnings = this.warnings.filter((msg) => msg.category !== category);
      } else {
        this.warnings = [];
      }
    },
    resetErrors(category) {
      if(category !== null && category !== undefined) {
        this.errors = this.errors.filter((msg) => msg.category !== category);
      } else {
        this.errors = [];
      }
    },

    addMessage(message) {
      switch (message.type) {
        case "error":
          this.addError(message);
          break;
        case "warning":
          this.addWarning(message);
          break;
        case "info":
          this.addInfo(message);
          break;
        default:
          logger.debug("Unknown message type: " + message.type);
      }
    },
    addError(error) {
      this.errors.push(error);
    },
    addWarning(warning) {
      this.warnings.push(warning);
    },
    addInfo(info) {
      this.infos.push(info);
    },
    clearMessages() {
      this.resetMessages(null);
    },
    removeMessage(message) {
      if(message === null || message === undefined) {
        return
      } else if (message.type === "info") {
        this.infos = this.infos.filter((msg) => msg.id !== message.id);
      } else if (message.type === "warning") {
        this.warnings = this.warnings.filter((msg) => msg.id !== message.id);
      } else if (message.type === "error") {
        this.errors = this.errors.filter((msg) => msg.id !== message.id);
      }
    },
  },


  getters: {
    isShowGlobalError: (state) => {
      return (
        state.errors &&
        state.errors.length > 0 &&
        state.errors.filter((error) => error.global).length > 0
      );
    },
    isShowLocalError: (state) => {
      return (
        state.errors &&
        state.errors.length > 0 &&
        state.errors.filter((error) => !error.global).length > 0
      );
    },
    isShowGlobalWarning: (state) => {
      return state.warnings && state.warnings.length > 0;
    },
    isShowGlobalInfo: (state) => {
      return (
        state.infos &&
        state.infos.length > 0 &&
        state.infos.filter((info) => info.global).length > 0
      );
    },
    isShowLocalInfo: (state) => {
      return (
        state.infos &&
        state.infos.length > 0 &&
        state.infos.filter((info) => !info.global).length > 0
      );
    },
    isShowMessages: (state) => {
      return (
        state.errors.filter((error) => error.showMessage).length > 0 ||
        state.warnings.filter((warning) => warning.showMessage).length > 0 ||
        state.infos.filter((info) => info.showMessage).length > 0
      );
    },
    isShowToasts: (state) => {
      return (
        state.errors.filter((error) => error.showToast).length > 0 ||
        state.warnings.filter((warning) => warning.showToast).length > 0 ||
        state.infos.filter((info) => info.showToast).length > 0
      );
    },
    isShowErrorToasts: (state) => {
      return (
        state.errors.filter((error) => error.showToast).length > 0
      );
    },
    isShowWarningToasts: (state) => {
      return (
        state.warnings.filter((warning) => warning.showToast).length > 0
      );
    },
    isShowInfoToasts: (state) => {
      return (
        state.info.filter((info) => info.showToast).length > 0
      );
    },    
    getToasts: (state) => {
      let toasts = [];
      state.errors.forEach((error) => {
        if (error.showToast) {
          toasts.push(error);
        }
      });
      state.warnings.forEach((warning) => {
        if (warning.showToast) {
          toasts.push(warning);
        }
      });
      state.infos.forEach((info) => {
        if (info.showToast) {
          toasts.push(info);
        }
      });
      return toasts;
    },
    getErrorToasts: (state) => {
      let toasts = [];
      state.errors.forEach((error) => {
        if (error.showToast) {
          toasts.push(error);
        }
      });
      return toasts;
    },
    getWarningToasts: (state) => {
      let toasts = [];
      state.warnings.forEach((warning) => {
        if (warning.showToast) {
          toasts.push(warning);
        }
      });
      return toasts;
    },
    getInfoToasts: (state) => {
      let toasts = [];
      state.infos.forEach((info) => {
        if (info.showToast) {
          toasts.push(info);
        }
      });
      return toasts;
    },
    getErrors: (state) => {
      return state.errors;
    },
    getWarnings: (state) => {
      return state.warnings;
    },
    getInfos: (state) => {
      return state.infos;
    },
    getGlobalErrorMessages: (state) => {
      return state.errors.filter((error) => error.showMessage && error.global);
    },
    getLocalErrorMessages: (state) => {
      return state.errors.filter((error) => error.showMessage && !error.global);
    },
    getGlobalWarningMessages: (state) => {
      return state.warnings.filter(
        (warning) => warning.showMessage && warning.global
      );
    },
    getLocalWarningMessages: (state) => {
      return state.warnings.filter(
        (warning) => warning.showMessage && !warning.global
      );
    },
    isShowGlobalErrorMessages: (state) => {
      return (
        state.errors &&
        state.errors.filter((error) => error.showMessage && error.global)
          .length > 0
      );
    },
    isShowLocalErrorMessages: (state) => {
      return (
        state.errors &&
        state.errors.filter((error) => error.showMessage && !error.global)
          .length > 0
      );
    },
    isShowGlobalWarningMessages: (state) => {
      return (
        state.warnings &&
        state.warnings.filter(
          (warning) => warning.showMessage && warning.global
        ).length > 0
      );
    },
    isShowLocalWarningMessages: (state) => {
      return (
        state.warnings &&
        state.warnings.filter(
          (warning) => warning.showMessage && !warning.global
        ).length > 0
      );
    },
  },
  persist: {
    storage: sessionStorage,
  },
});
