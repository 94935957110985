<template>
  <div class="row mb-3">
    <div class="col">
      <DataTable :value="addressActions"
                  scrollable scroll-height="60vh"
                  :sort-field="sortColumn" :sort-order="1" @sort="onSort"
                  data-key="function.Code"
                  v-model:filters="filters" filter-display="row"
                  ref="dt"
                  :loading="isLoading"
                  paginator :rows="50" :rows-per-page-options="[50, 100, 150, 200]"
                  paginatorTemplate="CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink JumpToPageDropdown"
                  :currentPageReportTemplate="`{first}` + ' ' + $t('pagination.to') + ' ' + `{last}` + ' ' + $t('pagination.of') + ' ' +  `{totalRecords}`"
                  csv-separator=";" :rowHover="true" :alwaysShowPaginator="false"
                  exportFilename="functions">

        <template #empty>
          <div class="alert alert-info">{{ $t('club.member.actions.notFound') }}</div>
        </template>
        <template #paginatorfirstpagelinkicon>
          <i class="bi bi-arrow-bar-left"/>
        </template>
        <template #paginatorprevpagelinkicon>
          <i class="bi bi-arrow-left"/>
        </template>
        <template #paginatornextpagelinkicon>
          <i class="bi bi-arrow-right"/>
        </template>
        <template #paginatorlastpagelinkicon>
          <i class="bi bi-arrow-bar-right"/>
        </template>

        <Column field="Description" filter-field="Description" filter-header-class="table-header-filter" :show-filter-menu="false" :sortable="true" :header-class="(sortColumn === 'Description' ? 'tableHeader text-primary' : 'tableHeader')" :header="$t('club.member.actions.action')">
          <template #body="{data}">
            {{ data.Description }}
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText v-model="filterModel.value" type="text" @input="filterCallback()" :placeholder="$t('section.clubMembers.filter', {filter: $t('club.member.actions.action')})" class="form-control input-filter"/>
          </template>
        </Column>

        <Column field="First_year" filter-field="First_year" filter-header-class="table-header-filter" :show-filter-menu="false" :sortable="true" :header-class="(sortColumn === 'First_year' ? 'tableHeader text-primary' : 'tableHeader')" :header="$t('club.member.actions.from')">
          <template #body="{data}">
            {{ data.First_year }}
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText v-model="filterModel.value" type="text" @input="filterCallback()" :placeholder="$t('section.clubMembers.filter', {filter: $t('club.member.actions.from')})" class="form-control input-filter"/>
          </template>
        </Column>

        <Column field="Last_year" filter-field="Last_year" filter-header-class="table-header-filter" :show-filter-menu="false" :sortable="true" :header-class="(sortColumn === 'Last_year' ? 'tableHeader text-primary' : 'tableHeader')" :header="$t('club.member.actions.to')">
          <template #body="{data}">
            {{ data.Last_year }}
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText v-model="filterModel.value" type="text" @input="filterCallback()" :placeholder="$t('section.clubMembers.filter', {filter: $t('club.member.actions.to')})" class="form-control input-filter"/>
          </template>
        </Column>

        <Column field="Points_each_year" filter-field="Points_each_year" filter-header-class="table-header-filter" :show-filter-menu="false" :sortable="true" :header-class="(sortColumn === 'Points_each_year' ? 'tableHeader text-primary' : 'tableHeader')" :header="$t('club.member.actions.pointsPerYear')">
          <template #body="{data}">
            {{ data.Points_each_year }}
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText v-model="filterModel.value" type="text" @input="filterCallback()" :placeholder="$t('section.clubMembers.filter', {filter: $t('club.member.actions.pointsPerYear')})" class="form-control input-filter"/>
          </template>
        </Column>

        <Column field="Points_in_total" filter-field="Points_in_total" filter-header-class="table-header-filter" :show-filter-menu="false" :sortable="true" :header-class="(sortColumn === 'Points_in_total' ? 'tableHeader text-primary' : 'tableHeader')" :header="$t('club.member.actions.pointsPerYear')">
          <template #body="{data}">
            {{ data.Points_in_total }}
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText v-model="filterModel.value" type="text" @input="filterCallback()" :placeholder="$t('section.clubMembers.filter', {filter: $t('club.member.actions.pointsInTotal')})" class="form-control input-filter"/>
          </template>
        </Column>

      </DataTable>
    </div>
  </div>

  <div class="row w-100 flex-center mb-3 mt-3">
    <Button :title="$t('export')" :label="$t('export')" @click="exportCSV($event)" class="btn btn-outline-primary datatable-export me-1 width-auto" unstyled>
      <i class="bi bi-filetype-csv"></i>
    </Button>
  </div>
  
  <BootstrapToast/>
</template>
  
<script setup>
  
  import Button from "primevue/button";
  
  import {computed, ref} from "vue";
  import {useClubMemberStore} from "@/store/modules/clubMember/clubMember";
  import {FilterMatchMode} from '@primevue/core/api';
  import BootstrapToast from "@/components/ui/BootstrapToast.vue";
  
  const clubMemberStore = useClubMemberStore();
  
  const dt = ref();  
  const sortColumn = ref('First_year');
  const filters = ref({
    'Description': {value: null, matchMode: FilterMatchMode.CONTAINS},
    'First_year': {value: null, matchMode: FilterMatchMode.CONTAINS},
    'Last_year': {value: null, matchMode: FilterMatchMode.CONTAINS},
    'Points_each_year': {value: null, matchMode: FilterMatchMode.CONTAINS},
    'Points_in_total': {value: null, matchMode: FilterMatchMode.CONTAINS},
  });
  
  const isLoading = computed(() => {
    return !(addressActions.value);
  });
  
  const addressActions = computed(() => {

    const actions = clubMemberStore.getAddressActions
    if(actions && actions.length > 0) {
      const year = new Date().getFullYear();
      actions.forEach(action => {
        let lastYear = Number.isInteger(action.Last_year) ? action.Last_year : year;
        let firstYear = Number.isInteger(action.First_year) ? action.First_year : null;
        
        if(lastYear !== null && firstYear !== null) {
          if(lastYear === firstYear) {
            action.Points_in_total = action.Points_each_year;
          } else {
            action.Points_in_total = (lastYear-firstYear) * action.Points_each_year;
          }
        } else {
          action.Points_in_total = null
        }
      });
    }
    return actions;
  });
  
  const onSort = (event) => {
    sortColumn.value = event.sortField;
  };
  
  const exportCSV = () => {
    dt.value.exportCSV({selectionOnly: false})
  };
  
</script>
  
  